<template>
  <div>
    <vs-row>
      <vs-col vs-w="12">
        <vs-input
          :value="$t('Inactivity')"
          class="w-full"
          :label="$t('Trigger')"
          disabled
      />
      </vs-col>
    </vs-row>
    <vs-row>
      <vs-col vs-w="12">
        <vs-input name="title" :value="automationData.title" type="text" class="w-full mt-4" :label="$t('Title')" disabled />
      </vs-col>
    </vs-row>
    <vs-col vs-w="12" class="mt-4">
      <div class="vs-component vs-con-input-label vs-input w-full vs-input-primary"  v-if="automationData.chatbot">
        <vs-input
          :value="automationData.chatbot.name"
          class="w-full"
          :label="$t('Chatbot')"
          disabled
      />
      </div> 
    </vs-col>
    <vs-col vs-w="12" class="mt-4">
      <div class="vs-component vs-con-input-label vs-input w-full vs-input-primary" v-if="automationData.automationSetup.intentions">
        <label class="vs-input--label">{{ $t("Intentions") }}</label>
        <vs-select
          class="w-full" 
          :value="automationData.automationSetup.intentions"  
          disabled 
        />
      </div>
    </vs-col>
    <vs-col vs-w="12" class="mt-4">
      <div class="vs-component vs-con-input-label vs-input w-full vs-input-primary" v-if="automationData.automationSetup.entities">
        <label class="vs-input--label">{{ $t("Entities") }}</label>
        <vs-select 
          class="w-full" 
          :disabled="true" 
          :value="automationData.automationSetup.entities"
          />
      </div>
    </vs-col>
    <vs-col vs-w="12" class="mt-4">
      <div class="vs-component vs-con-input-label vs-input w-full vs-input-primary" v-if="automationData.automationSetup.stages">
        <label class="vs-input--label">{{ $t("ConversationStage") }}</label>
        <vs-select 
          class="w-full" 
          :disabled="true" 
          :value="getLabels(stages, automationData.automationSetup.stages)"
          />
      </div>
    </vs-col>
    <vs-col vs-w="12" class="mt-4">
      <div class="vs-component vs-con-input-label vs-input w-full vs-input-primary" v-if="automationData.automationSetup.action">
        <vs-input
          :value="getLabels(actions, automationData.automationSetup.action)"
          class="w-full"
          :label="$t('Action')"
          disabled
      />
      </div>
    </vs-col>
    <vs-row>
      <vs-col vs-w="12" class="mt-4">
        <label class="vs-input--label">{{ $t("When") }}?</label>
        <div class="flex items-center">
          <label class="mr-4 mt-4 vs-input--label">{{ $t("SendAfter") }}</label>
          <div>
            <!-- <vs-input-number :label="$t('Day')" v-model="createAutomation.automationSetup.timeDay" :min="0" :max="7" /> -->
            <vs-row class="flex space-x-4">
              <vs-col class="flex-1">
                <vs-input 
                  :label="$t('Minutes')"
                  :value="automationData.automationSetup.timeMinute" 
                  disabled
                />
              </vs-col>
              <vs-col class="flex-1">
                <vs-input 
                  :label="$t('Hours').charAt(0).toUpperCase() + $t('Hours').slice(1)"
                  :value="automationData.automationSetup.timeHour"  
                  disabled
                />
              </vs-col>
            </vs-row>
          </div>
        </div>
      </vs-col>
    </vs-row>
    <vs-col vs-w="12" class="mt-4">
      <vs-textarea
        v-if="automationData.automationSetup.message !== null"
        name="message" 
        class="w-full my-3" 
        v-model="automationData.automationSetup.message"
        :value="automationData.automationSetup.message"
        style="cursor: not-allowed" 
        rows="5"
        disabled
      />
      <div class="vs-component vs-con-input-label vs-input w-full vs-input-primary">
        <vs-input
          v-if="automationData.template"
          :value="automationData.template ? automationData.template.name : null"
          class="w-full"
          :label="$t('Template')"
          disabled
        />
      </div> 
    </vs-col>
  </div>
</template>

<script>
export default {
  props: {
    automationId: {
      type: String,
      required: true,
    },
  },
  name: "viewAutomationPopup",
  data() {
    return {
      triggers: [
        { label: this.$t("Inactivity"), value: "inactivity" }
      ],
      actions: [
        { label: this.$t("SendMessage"), value: "sendMessage" },
        { label: this.$t("SendTemplate"), value: "sendTemplate" }
      ],
      stages: [
        // { label: this.$t("Campaign"), value: 1 },
        { label: this.$t("WaitingAttendance"), value: 2 },
        { label: this.$t("InAttendance"), value: 3 },
      ],
      automationData: {
        automationSetup: {
          intentions: [],
          entities: [],
          stages: [],
          action: null,
        },
        chatbot: null,
        template: null,
    }
  };
},
  methods: {
   async fetchAutomationData() {
    this.$vs.loading();
      const automationID = this.automationId
      const params = {
                acc: this.$store.state.acc.current_acc.id,
            };
      
      try {
         const response = await this.$http.get(`/p/automation/details/${automationID}`, {
              params: params,
              headers: {
                  Authorization: "Bearer " + this.$store.state.auth.accessToken,
                  "Content-Type": "Application/json",
              }
          });
          this.automationData = response.data.data[0];

          if (!this.automationData.automationSetup.intentions.length) {
              this.automationData.automationSetup.intentions.push(this.$t("All"));
          }

          if (!this.automationData.automationSetup.entities.length) {
              this.automationData.automationSetup.entities.push(this.$t("All"));
          }

      } catch (error) {
          this.$vs.notify({
              time: 2500,
              title: this.$t("Error"),
              text: this.$t("UnexpectedError"),
              iconPack: "feather",
              icon: "icon-x",
              color: "danger",
              position: "top-right",
          });
      } finally {
          this.$vs.loading.close();
      }
    },
    getLabels(array, values) {
      if (!Array.isArray(values)) {
        values = [values];
      }
      const filteredArray = array.filter((item) => {
        return values.includes(item.value);
      });
      const labels = filteredArray.map(item => item.label);
      return labels.length === 1 ? labels[0] : labels;
    }
  },
  beforeMount() {
    this.fetchAutomationData();
  }
}
</script>
<style>
.vs-input-number span {
  width: 42.88px;
}

.v-select.disabled {
  cursor: not-allowed;
}
</style>
