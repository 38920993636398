<template>
  <div
    v-if="cardData !== undefined"
    class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base"
  >
    <vx-card id="card" :title="cardTitle" :cardHeader="cardHeader">
      <p v-if="cardData.length === 0">Não há dados para exibir</p>
      <ul id="List" class="mt-5 flex pr-5 flex-col space-y-4">
        <li
          v-for="(item, index) in sortedData"
          :key="index"
          class="flex justify-between"
        >
          <p id="liText" class="text-lg">
            {{
              !item.intention || item.intention.length === 0
                ? $t("Others")
                : item.intention
            }}
          </p>
          <div class="flex space-x-4 items-center justify-center">
            <p class="text-lg">{{ item.conversations }}</p>
            <vs-chip color="success hidden">
              20%
              <feather-icon icon="ArrowUpIcon" svgClasses="h-4 w-4" />
            </vs-chip>
          </div>
        </li>
      </ul>
    </vx-card>
  </div>
</template>

<script>
export default {
  props: {
    cardTitle: String,
    cardData: Array,
    cardHeader: String,
  },
  computed: {
    sortedData: function () {
      const dataArr = this.cardData || [];
      return dataArr.sort((a, b) => {
        const intentionA = a.intention || ""; // Fallback to empty string if null/undefined
        const intentionB = b.intention || ""; // Fallback to empty string if null/undefined

        return intentionA.localeCompare(intentionB);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#List {
  overflow-y: auto;
  overflow-x: none;
  max-height: 300px;
}
#liText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 20px;
}
</style>
