<template>
  <div class="flex items-center mr-4">
    <small class="ml-2" v-if="operatorQueue">Na Fila?</small>
    <vs-switch
      color="success"
      class="ml-2"
      v-if="operatorQueue"
      @click="confirmActivateRecord"
      :checked="inQueue"
      style="display: inline-flex"
    />
  </div>
</template>

<script>
import axios from "@/axios";

export default {
  name: "OperatorQueue",
  props: {
    navbarColor: {
      type: String,
      default: "#fff",
    },
  },
  data() {
    return {
      inQueue: false,
      operatorQueue: false,
    };
  },
  computed: {
    navbarColorLocal() {
      return this.$store.state.theme === "dark" && this.navbarColor === "#fff"
        ? "#10163a"
        : this.navbarColor;
    },
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth;
    },
    textColor() {
      return {
        "text-white":
          (this.navbarColor != "#10163a" &&
            this.$store.state.theme === "dark") ||
          (this.navbarColor != "#fff" && this.$store.state.theme !== "dark"),
      };
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },

    // NAVBAR STYLE
    classObj() {
      return this.verticalNavMenuWidth == "default"
        ? "navbar-default pr-2"
        : this.verticalNavMenuWidth == "reduced"
        ? "navbar-reduced pr-2"
        : this.verticalNavMenuWidth
        ? "navbar-full pr-2"
        : "";
    },
  },
  async beforeMount() {
    const inbox = await axios.get(
      `/p/chat/inbox/${this.$store.state.acc.current_acc.id}`,
      { params: { acc: this.$store.state.acc.current_acc.id } }
    );

    await this.$store.dispatch(
      "chat/setRestrictOperator",
      inbox.data.data.restrictOperator === true
    );

    if (inbox.data.data.inbox.operatorQueue) {
      const operatorQueued = await axios.get(
        `/p/chat/operator/${this.$store.state.acc.current_acc.id}/${this.$store.state.user._id}`
      );
      this.inQueue =
        operatorQueued &&
        operatorQueued.data &&
        operatorQueued.data.data &&
        operatorQueued.data.data.inQueue;
      this.operatorQueue =
        inbox &&
        inbox.data &&
        inbox.data.data &&
        inbox.data.data.inbox &&
        inbox.data.data.inbox.operatorQueue;
    }
  },
  methods: {
    showSidebar() {
      this.$store.commit("TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE", true);
    },
    confirmActivateRecord() {
      if (this.inQueue) {
        this.$vs.dialog({
          type: "confirm",
          color: "danger",
          title: this.$t("RemoveOperatorQueueAnswerTitle"),
          text: this.$t("RemoveOperatorQueueAnswerLabel"),
          accept: this.toggleQueue,
          acceptText: this.$t("Remover"),
          cancelText: this.$t("Cancel"),
        });
      } else {
        this.$vs.dialog({
          type: "confirm",
          color: "success",
          title: this.$t("AddOperatorQueueAnswerTitle"),
          text: this.$t("AddOperatorQueueAnswerLabel"),
          accept: this.toggleQueue,
          acceptText: this.$t("Adicionar"),
          cancelText: this.$t("Cancel"),
        });
      }
    },
    toggleQueue() {
      axios
        .put("/g/user/" + this.$store.state.user._id, {
          inQueue: !this.inQueue,
          checkInQueue: this.operatorQueue,
          acc: this.$store.state.acc.current_acc.id,
        })
        .then((response) => {
          if (response.data.status === 1) {
            this.inQueue = !this.inQueue;
            this.$vs.notify({
              color: "success",
              title: this.$t("Success"),
              text: this.$t("InformationChanged"),
              position: "top-right",
              time: 4000,
            });
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: this.$t("UnexpectedError"),
            text: error || error.response.data.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "warning",
            position: "top-right",
            time: 4000,
          });
        });
    },
  },
};
</script>
