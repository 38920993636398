<template>
  <div class="">
    <vs-input
      v-validate
      data-vv-validate-on="blur"
      type="email"
      name="email"
      icon-no-border
      icon="icon icon-user"
      icon-pack="feather"
      label="Email"
      v-model="email"
      v-on:keyup.enter="loginJWT"
      class="w-full"
    />
    <span class="text-danger text-sm">{{ errors.first("email") }}</span>

    <vs-input
      data-vv-validate-on="blur"
      v-validate
      type="password"
      name="password"
      icon-no-border
      icon="icon icon-lock"
      icon-pack="feather"
      label="Senha"
      v-model="password"
      v-on:keyup.enter="loginJWT"
      autocomplete="off"
      class="w-full mt-6"
    />
    <span class="text-danger text-sm">{{ errors.first("password") }}</span>

    <div class="flex flex-wrap justify-between my-5">
      <vs-checkbox v-model="checkbox_remember_me" class="mb-3">{{
        $t("RemindMe")
      }}</vs-checkbox>
      <router-link to="/forgot-password"
        >{{ $t("ForgotPassword") }}?</router-link
      >
    </div>
    <div class="flex flex-wrap justify-end">
      <vs-button :disabled="!validateForm" @click="loginJWT">{{
        $t("Login")
      }}</vs-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      password: "",
      checkbox_remember_me: false,
    };
  },
  props: ["emitVerifyParent"],
  computed: {
    validateForm() {
      return !this.errors.any() && this.email != "" && this.password != "";
    },
  },
  methods: {
    redirectUser() {
      this.$vs.loading.close();
      const toRoute =
        this.$router.currentRoute.query.to &&
        this.$router.currentRoute.query.to.indexOf("login") > -1
          ? "/"
          : this.$router.currentRoute.query.to;
      if (toRoute) this.$router.push(toRoute);
      else if (
        this.$store.state.acc.current_acc.accPlan.plan.planParameters[
          "inboxAvailable"
        ] === true
      )
        this.$router.push({ name: "inbox" });
      else this.$router.push(toRoute || "/");
    },
    loginJWT() {
      this.$vs.loading();
      const payload = {
        remember_me: this.checkbox_remember_me,
        userDetails: {
          email: this.email.toLowerCase(),
          password: this.password,
        },
      };
      this.$store
        .dispatch("auth/loginJWT", payload)
        .then((response) => {
          //this.$db.delete();
          this.$store
            .dispatch("acc/setCurrentAccount", {
              acc: response.data.data[0]._id,
            })
            .then(() => {
              this.$acl.change(this.$store.state.user.userRole);
              this.redirectUser();
            });
        })
        .catch((error) => {
          this.$vs.loading.close();

          if (typeof error.request !== "undefined") {
            if (typeof error.request.response !== "undefined") {
              try {
                const parsedResponse = JSON.parse(error.request.response);
                error.message = parsedResponse.message;
              } catch (e) {
                error.message = this.$t("UnexpectedError");
              }
            } else {
              error.message = this.$t("UnexpectedError");
            }
          } else {
            error.message = this.$t("ComputerOfflineExplain");
          }
          this.$vs.notify({
            title: this.$t("Error"),
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
            position: "top-right",
            time: 4000,
          });
        });
    },
    registerUser() {
      this.$router.push("/register").catch(() => {});
    },
  },
  mounted() {
    this.$vs.loading.close();
    if (
      localStorage.getItem("accessToken") &&
      this.$store.state.acc.current_acc.id !== 0
    )
      this.redirectUser(this.$store.state.acc.current_acc.id);
  },
};
</script>
