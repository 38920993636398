<template>
  <div id="page-user-list">
    <!-- date-picker -->
    <vs-popup :title="$t('ChooseDate')" :active.sync="popupDatePicker">
      <!-- popup body -->
      <date-picker ref="customDatePicker" :key="customDatePickerKey" v-model="datePickerValue"
        :default-value="datePickerValueDefault" :disabled-date="disabledDate" format="DD-MM-YYYY" range inline confirm
        @confirm="handleConfirmDatePicker" />
    </vs-popup>
    <div class="vx-card p-6">
      <vs-row>
        <vs-col vs-w="5">
          <p class="text-lg font-semibold">
            Extrato das conversas<br><br>
          </p>
          <p class="text-sm">
            Aqui você irá encontrar o volume total de conversas do Whatsapp, Inteligência Artificial (IA), etc.<br>
            Esses números são utilizados únicamente para fins de faturamento conforme regra de cada canal.
          </p>
        </vs-col>
        <vs-col vs-w="7" vs-type="flex" class="items-end gap-x-6 justify-end">
          <vs-select label="Chatbot" size="small" v-model="chatbotSelected.value">
            <vs-select-item v-for="(item, index) in chatbotList" :key="index" :value="item.value" :text="item.label" />
          </vs-select>
          <vs-select label="Data" icon="icon-calendar" icon-pack="feather" size="small" v-model="timeChooseValue">
            <vs-select-item v-for="(item, index) in timeOptions" :key="index" :value="item" :text="item" />
            <div @click="popupDatePicker = true">
              <vs-select-item :value="timeCustomValue" :text="timeCustomValue" class="w-full" />
            </div>
          </vs-select>
          <div>
            <vs-button size="medium" class="mb-4 md:mb-0 add-user"
              color="success" @click="preGetExtract()">
              {{ $t("FilterSearch") }}
            </vs-button>
          </div>
        </vs-col>
      </vs-row>
      <vs-table stripe hoverFlat noDataText="" class="mt-5">
        <template slot="thead">
          <vs-th>{{ $t("ConversationType") }}</vs-th>
          <vs-th>{{ $t("ConversationsQuantity") }}</vs-th>
        </template>
        <template>
          <vs-tr>
            <vs-td class="truncate max-w-xs whitespace-nowrap"> Conversas incentivadas</vs-td>
            <vs-td class="truncate max-w-xs whitespace-nowrap">{{ extract ? extract.conversationsEncouraged : 0
              }}</vs-td>
          </vs-tr>
          <vs-tr>
            <vs-td class="truncate max-w-xs whitespace-nowrap"> Conversas receptivas</vs-td>
            <vs-td class="truncate max-w-xs whitespace-nowrap">{{ extract ? extract.conversationsReceptive : 0
              }}</vs-td>
          </vs-tr>
          <vs-tr>
            <vs-td class="truncate max-w-xs whitespace-nowrap"> Conversas ativas</vs-td>
            <vs-td class="truncate max-w-xs whitespace-nowrap">{{ extract ? extract.conversationsActive : 0
              }}</vs-td>
          </vs-tr>
          <vs-tr>
            <vs-td class="truncate max-w-xs whitespace-nowrap">Conversa de IA</vs-td>
            <vs-td class="truncate max-w-xs whitespace-nowrap">{{ extract ? extract.iaConversations : 0 }}</vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pt-br";

export default {
  components: {
    DatePicker
  },
  data() {
    return {
      extract: [],
      usersLoading: true,
      customDatePickerKey: 0,
      popupDatePicker: false,
      datePickerValueDefault: [
        new Date(new Date().getTime() - 90 * 24 * 60 * 60 * 1000), // 90 days ago
        new Date(), // today's date
      ],
      datePickerValue: null,
      timeChooseValue: "Este mês",
      timeCustomValue: "Escolher Data",
      timeOptions: [
        "Este mês",
        "Mês passado",
        "Esta semana",
        "Semana passada",
        "Este ano",
        "Ano passado",
      ],
      chatbotSelected: {
        label: "",
        value: "",
      },
      chatbotList: [],
      loading: true
    };
  },
  computed: {
    getDateByPeriod() {
      const today = new Date();
      let rangeDate;
      switch (this.timeChooseValue) {
        case "Este mês":
          rangeDate = {
            start: new Date(today.getFullYear(), today.getMonth(), 1),
            end: new Date(),
          };
          break;
        case "Mês passado":
          rangeDate = {
            start: new Date(today.getFullYear(), today.getMonth() - 1, 1),
            end: new Date(today.getFullYear(), today.getMonth(), 0),
          };
          break;
        case "Esta semana": {
          const firstDayOfWeek = today.getDate() - today.getDay();
          rangeDate = {
            start: new Date(
              today.getFullYear(),
              today.getMonth(),
              firstDayOfWeek
            ),
            end: today,
          };
          break;
        }
        case "Semana passada": {
          const lastWeekStart = new Date(today);
          lastWeekStart.setHours(0, 0, 0, 0);
          const lastWeekEnd = new Date(lastWeekStart);
          lastWeekStart.setDate(today.getDate() - today.getDay() - 7);
          lastWeekEnd.setDate(lastWeekStart.getDate() + 7);
          rangeDate = {
            start: lastWeekStart,
            end: lastWeekEnd,
          };
          break;
        }
        case "Este ano":
          rangeDate = {
            start: new Date(today.getFullYear(), 0, 1),
            end: today,
          };
          break;
        case "Ano passado":
          rangeDate = {
            start: new Date(today.getFullYear() - 1, 0, 1),
            end: new Date(today.getFullYear(), 0, 0),
          };
          break;
        default:
          // eslint-disable-next-line no-case-declarations
          const finish = new Date(this.datePickerValue[1]);
          finish.setDate(finish.getDate() + 1);

          rangeDate = {
            start: new Date(this.datePickerValue[0]),
            end: finish,
          };
          break;
      }

      return rangeDate;
    }
  },
  watch: {},
  methods: {
    async getChats() {
      const chatList = await this.$http.get("/p/chat/list", { params: { acc: this.$store.state.acc.current_acc.id } });
      if (!chatList) return;

      this.chatbotSelected = {
        label: chatList.data.data[0].name,
        value: chatList.data.data[0]._id,
      }
      for (var i = 0; i < chatList.data.data.length; i++) {
        this.chatbotList.push({
          label: chatList.data.data[i].name,
          value: chatList.data.data[i]._id,
        });
      }
    },
    async preGetExtract() {
      this.$vs.loading();
      this.loading = true;
      await this.getExtract();
      this.loading = false;
      this.$vs.loading.close();
    },
    async getExtract() {
      const extracts = await this.$http.get(`/p/chat/inbox/extract`, {
        params: {
          acc: this.$store.state.acc.current_acc.id,
          dates: this.getDateByPeriod,
          chatbot: this.chatbotSelected.value,
        },
      });
      if (!extracts) return;
      if (extracts.data.data) this.extract = extracts.data.data;
    },
    disabledDate(date) {
      const today = new Date().setHours(0, 0, 0, 0)
      return date > new Date(today);
    },
    handleConfirmDatePicker() {
      this.popupDatePicker = false;
      this.customDatePickerKey += 1;
      const dataIncio = this.datePickerValue[0].toLocaleDateString('pt-BR');
      const dataFim = this.datePickerValue[1].toLocaleDateString('pt-BR');
      this.timeChooseValue = `${dataIncio} ~ ${dataFim}`
    },
  },
  async mounted() {
    this.$vs.loading();
    await this.getChats();
    await this.getExtract();
    this.$vs.loading.close();
  },
};
</script>

<style lang="scss">
.userTable .vs-table--header {
  flex-direction: row-reverse;
  margin-bottom: 1rem;
}

.vs-table--search-input {
  border-radius: 5px;
}

.vuesax-app-is-ltr .vs-table--search {
  margin-left: 0;
}

#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}

.custom-input {
  width: 100%;
  padding: 0.7rem !important;
  font-size: 1rem !important;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;

  &:focus {
    border: 1px solid rgba(var(--vs-primary), 1) !important;
  }
}

.userTable .userDisabled td span {
  color: #d2d2d2;
}
</style>
