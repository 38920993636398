<template>
  <div
    v-if="cardData !== undefined"
    class="w-full h-full mb-base"
  >
    <vx-card
      id="card"
      class="h-full flex flex-col"
      :title="`${cardTitle} (${sortedData.length})`"
    >
      <p v-if="cardData.length === 0">Não há dados para exibir</p>
      <ul
        id="List"
        class="mt-4 flex-1 flex flex-col pr-5 space-y-7"
      >
        <li v-for="(item, index) in sortedData" :key="index" class="flex justify-between items-center">
          <div id="liText" class="flex items-center">
            <OnlineCircleComponent :online="isOnline(item)"/>
            <p class="ml-2 text-lg bold truncate">
              {{ item.name.length > 14 ? item.name.substring(0, 14) + '...' : item.name }}
            </p>
          </div>
            {{ isOnline(item) ? "Online" : calcTime(item.lastSeenDate) }}
        </li>
      </ul>
    </vx-card>
  </div>
</template>

<script>
import { differenceInHours, differenceInMinutes } from "date-fns";
export default {
  components: {
    OnlineCircleComponent: () =>
      import("@/components/OnlineCircleComponent.vue"),
  },
  props: {
    cardTitle: String,
    cardData: Array,
  },
  methods: {
    isOnline(user) {
      if (user.online) return true;
    },
    calcTime(time) {
      if (!time) return "-";
      let date_time_to = new Date(Date.parse(time));
      if (!new Date(time).getTime() > 0) date_time_to = new Date(time);
      let date_time_from = new Date();
      let finalDate = this.toDate(time);
      let hours = differenceInHours(date_time_from, date_time_to);
      if (hours < 24) {
        if (hours > 0) {
          finalDate = `${hours}h`;
        } else {
          let minutes = differenceInMinutes(date_time_from, date_time_to);
          finalDate = minutes === 0 ? '1min' : `${minutes}min`;
        }
      }

      return finalDate;
    },
    toDate(time) {
      let date_obj = new Date(Date.parse(time));
      let valid = new Date(time).getTime() > 0;
      if (!valid) date_obj = new Date(time);

      let locale = "en-us";
      let monthName = date_obj.toLocaleString(locale, {
        month: "short",
      });
      return date_obj.getDate() + " " + monthName;
    },
  },
  computed: {
    sortedData() {
      const dataArr = this.cardData || [];

      return dataArr
        .filter(item => item.status === 1)
        .sort((a, b) => {
          if (!a.lastSeenDate) return 1;
          if (!b.lastSeenDate) return -1;

          if (a.online && !b.online) return -1;
          if (!a.online && b.online) return 1;

          return new Date(b.lastSeenDate) - new Date(a.lastSeenDate);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#card {
  min-height: 100px;
}

#List {
  overflow-y: auto;
  box-sizing: border-box;
  max-height: 1740px;
}

#liText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 1500px;
}

*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 20px;
}
</style>
