var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('vs-row',{staticClass:"mt-5"},[_c('vs-col',{attrs:{"vs-w":"12"}},[_c('div',{staticClass:"helloCard py-4 px-6 flex items-center justify-between rounded-md mb-5"},[_c('p',{staticClass:"text-xl text-primary"},[_vm._v(" Olá 👋, "),_c('strong',[_vm._v(_vm._s(_vm.userName))]),_vm._v("! "+_vm._s(_vm.fullDate)+" ")]),_c('vs-button',{attrs:{"icon-pack":"feather","icon":"icon-chevrons-right","icon-after":"","to":"/apps/inbox"}},[_vm._v(" Acessar Inbox ")])],1)])],1),(_vm.$acl.check('manager') && !_vm.useV2)?_c('div',{staticClass:"w-full mt-4"},[_c('div',{staticClass:"vx-row"},[_c('CardContainer',{key:"cardCampaign",attrs:{"cardTitle":"Campanhas","cardHeader":"Conversas","cardIcon":"SendIcon","statsDiff":0,"cardValue":_vm.statsData.conversationCampaign,"modalText":_vm.statsData.conversationCampaign +
          ' é o número de conversas de campanhas.'}}),_c('CardContainer',{key:`card1-${_vm.chatContactsKey}`,attrs:{"cardHeader":"Conversas","cardTitle":"Aguardando atendimento","cardValue":_vm.statsData.waitingAttendant,"cardIcon":'ClockIcon',"modalText":`${_vm.statsData.waitingAttendant} conversas estão esperando atendimento`,"statsDiff":0}}),_c('CardContainer',{attrs:{"cardHeader":"Conversas","cardTitle":"Em atendimento","cardIcon":'MessageSquareIcon',"modalText":`${_vm.statsData.inAttendance} conversas estão em atendimento nesse momento`,"cardValue":_vm.statsData.inAttendance,"statsDiff":0}}),_c('CardContainer',{attrs:{"cardHeader":"Conversas","cardTitle":"Total","cardValue":_vm.statsData.inAttendance +
          _vm.statsData.waitingAttendant +
          _vm.statsData.conversationCampaign,"cardIcon":'UsersIcon',"modalText":_vm.statsData.inAttendance +
          _vm.statsData.waitingAttendant +
          ' é o total de conversas ativas',"statsDiff":0}})],1),(_vm.$acl.check('manager'))?_c('div',{staticClass:"vx-row max-h-full"},[_c('CardUsersOnline',{key:`card1-${_vm.chatContactsKey}`,attrs:{"cardTitle":'Usuários da Conta',"cardData":_vm.statsData.userList}}),_c('CardCardConversationByIntention',{key:`card2-${_vm.chatContactsKey}`,attrs:{"cardTitle":"Por intenção","cardHeader":"Conversas","cardData":_vm.statsData.conversationByIntention}}),_c('CardConversationByEntity',{key:`card3-${_vm.chatContactsKey}`,attrs:{"cardTitle":"Por entidade chave","cardHeader":"Conversas","cardData":_vm.statsData.conversationByEntity}}),_c('CardConversationByAttendent',{key:`card4-${_vm.chatContactsKey}`,attrs:{"cardHeader":"Conversas","cardTitle":"Por atendente","cardData":_vm.statsData.conversationByAttendant}})],1):_vm._e()]):_vm._e(),(_vm.$acl.check('manager') && _vm.useV2)?_c('div',{staticClass:"w-full mt-4"},[_c('div',{staticClass:"vx-row"},[_c('CardContainer',{key:"cardCampaign",attrs:{"cardTitle":"Campanhas","cardHeader":"Conversas","cardIcon":"SendIcon","statsDiff":0,"cardValue":_vm.newData.conversations.totals[0].totalCampaign,"modalText":_vm.statsData.conversationCampaign +
          ' é o número de conversas de campanhas.'}}),_c('CardContainer',{key:`card1-${_vm.chatContactsKey}`,attrs:{"cardHeader":"Conversas","cardTitle":"Aguardando atendimento","cardValue":_vm.newData.conversations.totals[0].totalWaitingAttendance,"cardIcon":'ClockIcon',"modalText":`${_vm.statsData.waitingAttendant} conversas estão esperando atendimento`,"statsDiff":0}}),_c('CardContainer',{attrs:{"cardHeader":"Conversas","cardTitle":"Em atendimento","cardIcon":'MessageSquareIcon',"modalText":`${_vm.statsData.inAttendance} conversas estão em atendimento nesse momento`,"cardValue":_vm.newData.conversations.totals[0].totalInAttendance,"statsDiff":0}}),_c('CardContainer',{attrs:{"cardHeader":"Conversas","cardTitle":"Total","cardValue":_vm.newData.conversations.totals[0].total,"cardIcon":'UsersIcon',"modalText":_vm.statsData.inAttendance +
          _vm.statsData.waitingAttendant +
          ' é o total de conversas ativas',"statsDiff":0}})],1),(_vm.$acl.check('manager'))?_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full max-h-full sm:w-full md:w-full lg:w-1/4 xl:w-1/4 h-full"},[_c('CardUsersOnlineV2',{key:`card1-${_vm.chatContactsKey}`,attrs:{"cardTitle":'Usuários da Conta',"cardData":_vm.newData.userList}})],1),_c('div',{staticClass:"vx-col w-full sm:w-full md:w-full lg:w-3/4 xl:w-3/4"},[_c('CardConversationsTable',{key:`card2-${_vm.chatContactsKey}`,attrs:{"cardTitle":'Conversas por Intenção',"cardData":_vm.newData.conversations.conversationByIntention}}),_c('CardConversationsTable',{key:`card3-${_vm.chatContactsKey}`,attrs:{"cardTitle":'Conversas por Entidade-chave',"cardData":_vm.newData.conversations.conversationByEntity}}),_c('CardConversationsTable',{key:`card4-${_vm.chatContactsKey}`,attrs:{"cardTitle":'Conversas por Atendente',"cardData":_vm.newData.conversations.conversationByOperator}})],1)]):_vm._e()]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }