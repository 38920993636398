/*=========================================================================================
  File Name: moduleAccActions.js
  Description: Acc Module Actions

==========================================================================================*/

import axios from "@/axios.js";

export default {
  setAiFiles({ commit }, data) {
    commit("SET_AIFILES", data);
  },
  removeAiFileById({ commit }, id) {
    commit("REMOVE_AIFILE_BY_ID", id);
  },
  // /////////////////////////////////////////////
  // Account
  // /////////////////////////////////////////////
  createAcc({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const {
        name,
        companyIndustry,
        companyGoal,
        plan,
        whitelabel,
        companyEmployeeCount,
      } = payload;

      axios
        .post("/g/acc", {
          plan: plan,
          name: name,
          companyIndustry: companyIndustry,
          companyGoal: companyGoal,
          whitelabel: whitelabel,
          companyEmployeeCount,
        })
        .then((response) => {
          dispatch("acc/getAccounts");
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteAcc({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const { id } = payload;

      axios
        .delete("/g/acc/" + id)
        .then(() => {
          dispatch("getAccounts");
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getAccounts({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/g/acc/list")
        .then((response) => {
          var accList = [];
          for (var i = 0; i < response.data.data.length; i++) {
            accList.push({
              id: response.data.data[i]._id,
              label: response.data.data[i].name,
            });
          }
          commit("SET_ACCS", accList);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  removeAccAccess(n, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete("/g/acc/access/" + payload.acc + "/" + payload.id, {
          data: { acc: payload.acc },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  setCurrentAccount({ commit, dispatch }, { acc, accChange }) {
    return new Promise((resolve, reject) => {
      localStorage.setItem("activeProduct", "chatbot");
      if (accChange) {
        localStorage.setItem("activeAcc", acc);
        resolve(true);
        return;
      }
      commit("acc/SET_CURRENT_ACC_ID", acc, { root: true });
      axios
        .get("/g/acc/" + acc)
        .then((response) => {
          const accData = response.data.data;
          localStorage.setItem("activeAcc", acc);
          localStorage.setItem("automationEnable", accData.acc.automationEnable)
          commit("acc/SET_CURRENT_ACC", accData, { root: true });

          dispatch(
            "updateUserInfo",
            { userRole: accData.userAccAccessAcl },
            { root: true }
          );
          // set group to user in JUNE
          let statusAcc = "Active";
          switch (accData.acc.status) {
            case 0:
              statusAcc = "Disabled";
              break;
            case 1:
              statusAcc = "Active";
              break;
            case 2:
              statusAcc = "Deleted";
              break;
            default:
              statusAcc = "Pending";
              break;
          }
          if (window && window.analytics && window.analytics.group)
            window.analytics.group(accData.acc._id, {
              name: accData.acc.name,
              status: statusAcc,
              createdAt: accData.acc.createdAt,
              companyGoal: accData.acc.companyGoal,
              companyIndustry: accData.acc.companyIndustry,
              superlogicaId: accData.accFinancial ? accData.accFinancial.superlogicaId : null,
              planTitle: accData.plan.title
            });
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateAcc({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const { id, name, companyIndustry, companyGoal, intercom } = payload;

      axios
        .put("/g/acc/" + id, {
          name: name,
          companyIndustry: companyIndustry,
          companyGoal: companyGoal,
          intercom: intercom,
        })
        .then(() => {
          dispatch("setCurrentAccount", { acc: id }).then((response) => {
            resolve(response);
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateAccTimeZone({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const { id, timezone } = payload;

      axios
        .put("/g/acc/" + id, {
          timezone: timezone,
        })
        .then(() => {
          dispatch("setCurrentAccount", { acc: id }).then((response) => {
            resolve(response);
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateAccPlan({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const { acc, id } = payload;

      axios
        .put("/g/acc/" + acc + "/plan/" + id, payload)
        .then((response) => {
          dispatch("setCurrentAccount", { acc: response.data.data.acc }).then(
            (response) => {
              resolve(response);
            }
          );
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
