<!-- =========================================================================================
  File Name: TheNavbar.vue
  Description: Navbar component
  Component Name: TheNavbar
========================================================================================== -->


<template>
  <div class="relative">
    <div class="vx-nvbar-wrapper" :class="classObj">
      <div class="flex justify-between bg-gre px-1 mt-4 py-2">
        <feather-icon
          class="sm:inline-flex xl:hidden cursor-pointer px-4 py-2"
          icon="MenuIcon"
          @click.stop="showSidebar"
        />
        <div class="ml-1 sm:ml-3 md:ml-8 lg:ml-8 w-36 sm:w-36 md:w-26 lg:w-26 xl:w-26">
          <account-select />
        </div>
        <div class="flex">
          <OperatorQueue />
          <account-plan />
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import AccountPlan from "./components/AccountPlan.vue";
import AccountSelect from "./components/AccountSelect.vue";
import OperatorQueue from "./components/OperatorQueue.vue";

export default {
  name: "the-navbar-vertical",
  props: {
    navbarColor: {
      type: String,
      default: "#fff"
    }
  },
  components: {
    AccountSelect,
    AccountPlan,
    OperatorQueue
  },
  computed: {
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth;
    },
    textColor() {
      return {
        "text-white":
          (this.navbarColor !== "#10163a" &&
            this.$store.state.theme === "dark") ||
          (this.navbarColor !== "#fff" && this.$store.state.theme !== "dark")
      };
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },

    // NAVBAR STYLE
    classObj() {
      return this.verticalNavMenuWidth === "default"
        ? "navbar-default pr-2"
        : this.verticalNavMenuWidth === "reduced"
        ? "navbar-reduced pr-2"
        : this.verticalNavMenuWidth
        ? "navbar-full pr-2"
        : "";
    }
  },
  methods: {
    showSidebar() {
      this.$store.commit("TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE", true);
    },
  }
};
</script>

