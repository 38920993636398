/* eslint-disable no-unused-vars */
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken as getTokenFirebase,
  deleteToken as deleteTokenFirebase,
  isSupported,
} from "firebase/messaging";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDADaXACd78GbXXFxIZkU8K94mpYLdNUxs",
  authDomain: "duotalk-app.firebaseapp.com",
  projectId: "duotalk-app",
  storageBucket: "duotalk-app.appspot.com",
  messagingSenderId: "392140658046",
  appId: "1:392140658046:web:8d7ea311038a61b95d4f2f",
  measurementId: "G-PEPQFZX13F",
};

let messaging = null;
let initialized = false;

const initializeMessaging = async () => {
  if (!initialized && process.env.VUE_APP_FIREBASE_ENABLED === "true") {
    const firebaseApp = initializeApp(firebaseConfig);

    const supported = await isSupported();
    if (supported) {
      messaging = getMessaging(firebaseApp);
      initialized = true;
    }
  }
  return messaging;
};

const getToken = async () => {
  await initializeMessaging();
  if (messaging) {
    try {
      const token = await getTokenFirebase(messaging, { vapidKey: process.env.VUE_APP_VAPID_KEY });
      return token;
    } catch (e) {
      return null;
    }
  }
  return null;
};

const deleteToken = async () => {
  await initializeMessaging();
  if (messaging) {
    try {
      return await deleteTokenFirebase(messaging);
    } catch (e) {
      return false;
    }
  }
  return false;
};

export { initializeMessaging, getToken, deleteToken };