export class PinConversationHelper {
  static KEY = "@pinnedConversations";

  /** @return {string[]} */
  static getConversationIds(operatorId) {
    if (!operatorId) {
      return [];
    }

    const item = localStorage.getItem(`${this.KEY}:${operatorId}`);

    if (!item) {
      localStorage.setItem(`${this.KEY}:${operatorId}`, "[]");
      return [];
    }

    return JSON.parse(item);
  }

  static pinned(conversationId, operatorId) {
    const conversationIds = this.getConversationIds(operatorId);
    return conversationIds.includes(conversationId);
  }

  static pin(conversationId, operatorId) {
    if (!operatorId || !conversationId) {
      return false;
    }

    const conversationIds = this.getConversationIds(operatorId);
    if (conversationIds.length < 3) {
      conversationIds.push(conversationId);
      localStorage.setItem(
        `${this.KEY}:${operatorId}`,
        JSON.stringify(conversationIds)
      );
      return true;
    }

    return false;
  }
  static unpin(conversationId, operatorId) {
    if (!operatorId || !conversationId) {
      return false;
    }

    const conversationIds = this.getConversationIds(operatorId);
    if (conversationIds.length > 0) {
      const index = conversationIds.findIndex((id) => id === conversationId);

      if (index !== -1) {
        conversationIds.splice(index, 1);
        localStorage.setItem(
          `${this.KEY}:${operatorId}`,
          JSON.stringify(conversationIds)
        );
        return true;
      }
    }

    return false;
  }
}
