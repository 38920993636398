/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Strucutre:
          url     => router path
          name    => name to display in sidebar
          slug    => router path name
          icon    => Feather Icon component/icon name
          tag     => text to display on badge
          tagColor  => class to apply on badge element
          i18n    => Internationalization
          submenu   => submenu of current item (current item will become dropdown )
                NOTE: Submenu don't have any icon(you can add icon if u want to display)
          isDisabled  => disable sidebar item/group
==========================================================================================*/


export default [
  {
    url: "/dashboard",
    name: "Dashboard",
    icon: "BarChartIcon",
    slug: "dashboard",
    i18n: "Dashboard",
  },
  {
    url: "/apps/inbox",
    name: "Inbox",
    slug: "inbox",
    icon: "InboxIcon",
    i18n: "Inbox",
    planCheck: { comparison: "eq", value: "inboxAvailable" },
  },
  {
    url: "/apps/chatbots",
    name: "Chatbots",
    slug: "chatbots",
    icon: "ZapIcon",
    i18n: "Chatbots",
  },
  {
    url: "/apps/feedback",
    name: "Feedback",
    slug: "feedback",
    icon: "ThumbsUpIcon",
    i18n: "Feedback",
    planCheck: { comparison: "eq", value: "inboxAvailable" }
  },
  {
    url: "/apps/campaigns",
    name: "Campaigns",
    slug: "campaigns",
    icon: "SendIcon",
    i18n: "Campaigns",
    planCheck: { comparison: "eq", value: "inboxAvailable" }
  },
  {
    url: "/apps/contacts",
    name: "contacts",
    slug: "contacts",
    icon: "UsersIcon",
    i18n: "Contacts",
    planCheck: { comparison: "eq", value: "inboxAvailable" }
  },
  {
    url: "/apps/automation",
    name: "automation",
    slug: "automation",
    icon: "GitPullRequestIcon",
    i18n: "Automation",
    planCheck: { comparison: "eq", value: "inboxAvailable" },
  },
  {
    url: "/apps/leads",
    name: "Leads",
    slug: "leads",
    icon: "UserPlusIcon",
    i18n: "Leads",
    planCheck: { comparison: "ne", value: "inboxAvailable" }
  },
  {
    url: "/profile",
    name: "Profile",
    slug: "profile",
    icon: "UserIcon",
    i18n: "Profile",
    bottom: true
  },
  {
    url: "/appstore",
    name: "AppStore",
    slug: "appstore",
    icon: "GridIcon",
    i18n: "AppStore",
    bottom: true
  },
  {
    url: "/settings",
    name: "Settings",
    slug: "Settings",
    icon: "SettingsIcon",
    i18n: "Settings",
    bottom: true
  },
  {
    url: "/logout",
    name: "Logout",
    slug: "logout",
    icon: "LogOutIcon",
    i18n: "Logout",
    bottom: true
  }
];
