<template>
  <div
    v-if="convPerType !== undefined"
    class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base"
  >
    <vx-card :title="cardTitle">
      <template slot="actions">
        <vs-tooltip title="O que é Outbound/Inbound?" text="Outbound: são conversas/contatos gerados ativamente pela sua equipe, ou seja, cadastros manuais. Inbound: são conversas/contatos gerados de forma receptiva, ou seja, gerados automaticamente.">
          <feather-icon icon="HelpCircleIcon" />
        </vs-tooltip>
      </template>
      <div slot="no-body">
        <ul class="scrollList mt-5 flex flex-col space-y-4 p-6">
          <li
            v-for="(conversationPerType, index) in conversationsPerType"
            :key="index"
            class="flex justify-between"
          >
            <p class="text-xl truncate" :title="index">{{ index }}</p>
            <div class="flex space-x-4 items-center justify-center">
              <p class="text-xl">{{ conversationPerType }}</p>
              <vs-chip color="success hidden">{{ getPorcentage(total, conversationPerType) }}%
                <feather-icon icon="ArrowUpIcon" svgClasses="h-4 w-4" />
              </vs-chip>
            </div>
          </li>
        </ul>
        <p v-if="!convPerType || Object.keys(convPerType).length === 0">
          Não há dados registrados nesse período de tempo 😔
        </p>
      </div>
    </vx-card>
  </div>
</template>
<script>
export default {
  mounted() {},
  methods: {
    getPorcentage(total, porcentage) {
      return total > 0 ? ((100 / total) * porcentage).toFixed(2) : 0;
    },
    teste() {
      this.isPopupActive = !this.isPopupActive;
    },
  },
  props: {
    cardTitle: String,
    convPerType: Object,
    total: Number,
    color: String,
  },
  data() {
    return {
      isPopupActive: false,
    };
  },
  computed: {
    conversationsPerType: function () {
      return this.convPerType
        ? Object.entries(this.convPerType)
            .sort((a, b) => b[1] - a[1])
            .reduce((_sortedObj, [k, v]) => ({ ..._sortedObj, [k]: v }), {})
        : {};
    },
  },
};
</script>

<style lang="scss" scoped>
.scrollList{
  overflow-y: auto;
  max-height: 200px;
  padding-right: 20px;
}
.popupContent {
  margin-bottom: 10px;

  span {
    font-weight: bold;
  }
}

.helpButton {
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    opacity: 0.4;
  }
}
*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: #eee;
  border-radius: 20px;
}

*::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 20px;
  transition: all .2s;
}
*::-webkit-scrollbar-thumb:hover {
  background-color: #777;
  border-radius: 20px;
}
</style>
