<!-- =========================================================================================
    File Name: UserProfile.vue
    Description: user profile sidebar
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="container-about" class="flex flex-col h-full">
    <div class="header-sidebar relative flex flex-col p-4" slot="header">
      <vs-button
        type="flat"
        color="dark"
        icon-pack="feather"
        icon="icon-x"
        radius
        size="large"
        class="absolute right-1 top-1"
        @click="$emit('close-profile-sidebar', false)"
      />

      <div class="flex justify-start items-center">
        <div class="mr-6">
          <vs-avatar
            v-if="contactProfilePic !== ''"
            class="m-0 border-white border-2 border-solid shadow-md"
            :src="contactProfilePic"
            size="80px"
          />
          <vs-avatar
            v-if="contactProfilePic === ''"
            class="m-0 border-white border-2 border-solid shadow-md"
            text-color="dark"
            :text="contactNameInitials"
            size="80px"
          />
        </div>
        <div>
          <h4
            id="contactName"
            @mouseover="showIconName = true"
            @mouseleave="showIconName = false"
            :class="{ showIconClass: showIconName }"
            @click="setShowNameInput(true)"
            class="text-left"
          >
            <template v-if="showNameInput === false">
              <svg
                width="0"
                viewBox="0 0 750 492"
                fill="#999"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="m304.140625 82.472656-270.976563 270.996094c-1.363281 1.367188-2.347656 3.09375-2.816406 4.949219l-30.035156 120.554687c-.898438 3.628906.167969 7.488282 2.816406 10.136719 2.003906 2.003906 4.734375 3.113281 7.527344 3.113281.855469 0 1.730469-.105468 2.582031-.320312l120.554688-30.039063c1.878906-.46875 3.585937-1.449219 4.949219-2.8125l271-270.976562zm0 0"
                />
                <path
                  d="m476.875 45.523438-30.164062-30.164063c-20.160157-20.160156-55.296876-20.140625-75.433594 0l-36.949219 36.949219 105.597656 105.597656 36.949219-36.949219c10.070312-10.066406 15.617188-23.464843 15.617188-37.714843s-5.546876-27.648438-15.617188-37.71875zm0 0"
                />
              </svg>
              <span style="margin-left: -6px">
                {{ currentContact.name || contactName }}
              </span>
            </template>
            <div
              :class="{ showInputClass: showNameInput }"
              class="showInputInitialClass"
            >
              <form
                @submit="updateContact('name')"
                action="javascript:void(0);"
              >
                <input
                  ref="contactID"
                  type="hidden"
                  name="contactID"
                  :value="contactID"
                />
                <input
                  :placeholder="$t('EnterName')"
                  ref="newContactName"
                  class="vs-inputx vs-input--input input-onboarding"
                  tabindex="-1"
                  v-on:blur="setShowNameInput(false)"
                  type="text"
                />
              </form>
            </div>
          </h4>
          <div class="flex justify-start mt-1">
            <ul>
              <li>
                <small
                  v-if="showPhoneInput === false"
                  @mouseover="showIconPhone = true"
                  @mouseleave="showIconPhone = false"
                  :class="{ showIconClass: showIconPhone }"
                >
                  <a
                    href="#"
                    v-if="
                      (currentContact.phone &&
                        currentContact.phone.length > 0) ||
                      (contactPhone && contactPhone.length > 0)
                    "
                    @click="setShowPhoneInput(true)"
                    class="text-left"
                  >
                    <svg
                      width="0"
                      viewBox="0 0 750 492"
                      fill="rgba(var(--vs-primary), 1)"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="m304.140625 82.472656-270.976563 270.996094c-1.363281 1.367188-2.347656 3.09375-2.816406 4.949219l-30.035156 120.554687c-.898438 3.628906.167969 7.488282 2.816406 10.136719 2.003906 2.003906 4.734375 3.113281 7.527344 3.113281.855469 0 1.730469-.105468 2.582031-.320312l120.554688-30.039063c1.878906-.46875 3.585937-1.449219 4.949219-2.8125l271-270.976562zm0 0"
                      />
                      <path
                        d="m476.875 45.523438-30.164062-30.164063c-20.160157-20.160156-55.296876-20.140625-75.433594 0l-36.949219 36.949219 105.597656 105.597656 36.949219-36.949219c10.070312-10.066406 15.617188-23.464843 15.617188-37.714843s-5.546876-27.648438-15.617188-37.71875zm0 0"
                      />
                    </svg>
                    {{
                      formatPhone(currentContact.phone) ||
                      formatPhone(contactPhone)
                    }}
                  </a>
                  <a href="#" v-else @click="setShowPhoneInput(true)">{{
                    $t("AddPhone")
                  }}</a>
                </small>
                <div
                  v-if="showPhoneInput"
                  :class="{ showInputClass: showPhoneInput }"
                  class="showInputInitialClass"
                >
                  <form
                    @submit="updateContact('phone')"
                    action="javascript:void(0);"
                  >
                    <vue-tel-input
                      v-model="newContactPhoneProp"
                      @validate="validatePhone"
                      class="w-full"
                      placeholder="Digite o telefone"
                      ref="newContactPhone"
                      tabindex="-1"
                      v-click-outside="setShowPhoneInput"
                    ></vue-tel-input>
                    <span v-if="!validPhone" class="text-danger text-sm">
                      Insira um telefone válido
                    </span>
                  </form>
                </div>
              </li>
              <li>
                <small
                  v-if="showEmailInput === false"
                  @mouseover="showIconEmail = true"
                  @mouseleave="showIconEmail = false"
                  :class="{ showIconClass: showIconEmail }"
                >
                  <a
                    href="#"
                    v-if="
                      (currentContact.email &&
                        currentContact.email.length > 0) ||
                      (contactEmail && contactEmail.length > 0)
                    "
                    @click="setShowEmailInput(true)"
                    class="text-left"
                  >
                    <svg
                      width="0"
                      viewBox="0 0 750 492"
                      fill="rgba(var(--vs-primary), 1)"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="m304.140625 82.472656-270.976563 270.996094c-1.363281 1.367188-2.347656 3.09375-2.816406 4.949219l-30.035156 120.554687c-.898438 3.628906.167969 7.488282 2.816406 10.136719 2.003906 2.003906 4.734375 3.113281 7.527344 3.113281.855469 0 1.730469-.105468 2.582031-.320312l120.554688-30.039063c1.878906-.46875 3.585937-1.449219 4.949219-2.8125l271-270.976562zm0 0"
                      />
                      <path
                        d="m476.875 45.523438-30.164062-30.164063c-20.160157-20.160156-55.296876-20.140625-75.433594 0l-36.949219 36.949219 105.597656 105.597656 36.949219-36.949219c10.070312-10.066406 15.617188-23.464843 15.617188-37.714843s-5.546876-27.648438-15.617188-37.71875zm0 0"
                      />
                    </svg>
                    {{
                      currentContact.email
                        ? formatEmail(currentContact.email)
                        : formatEmail(contactEmail)
                    }}
                  </a>
                  <a href="#" v-else @click="setShowEmailInput(true)"
                    >Adicionar email</a
                  >
                </small>
                <div
                  :class="{ showInputClass: showEmailInput }"
                  class="showInputInitialClass"
                >
                  <form
                    @submit="updateContact('email')"
                    action="javascript:void(0);"
                  >
                    <input
                      placeholder="Digite o email"
                      ref="newContactEmail"
                      class="vs-inputx vs-input--input input-onboarding"
                      tabindex="-1"
                      v-on:blur="setShowEmailInput(false)"
                      type="email"
                    />
                  </form>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="flex w-full justify-center mt-2">
        <vs-dropdown
          vs-trigger-click
          class="cursor-pointer hover:bg-primary-light rounded-xl"
        >
          <vs-button
            icon="icon-chevron-down"
            icon-pack="feather"
            size="small"
            type="border"
            class="px-1 rounded-xl"
            icon-after
          >
            {{ currentContact.phase || contactPhase }}
          </vs-button>
          <vs-dropdown-menu>
            <vs-dropdown-item
              class="pb-2 px-1"
              @click="onClickContactPhase(phaseVal)"
              v-for="(phaseVal, index) in phaseList"
              :key="index"
            >
              {{ phaseVal }}
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
        <vs-button
          type="border"
          color="primary"
          class="px-1 ml-3 rounded-xl"
          :disabled="!isAdmin || (optIn !== null && optIn !== undefined)"
          size="small"
          @click="lgpdPopUp = true"
        >
          {{
            optIn !== null && optIn !== undefined
              ? optIn
                ? "opt-in"
                : "opt-out"
              : "ativar opt-in"
          }}
        </vs-button>
        <vs-button
          type="border"
          color="danger"
          icon-pack="feather"
          icon="icon-x-circle"
          size="small"
          class="px-1 ml-3 rounded-xl"
          icon-after
          @click="popupBlockContact()"
          :disabled="
            !(
              $store.state.user.userRole === 'admin' ||
              $store.state.user.userRole === 'manager' ||
              $store.state.user.userRole === 'master'
            )
          "
        >
          {{ statusMutedChild === -1 ? "Desbloquear" : "Bloquear" }}
        </vs-button>
      </div>
    </div>
    <vs-divider class="d-theme-border-grey-light mt-0" />
    <VuePerfectScrollbar class="chat-scroll-area" :settings="settings">
      <div v-if="lastConversationsLoading">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          style="
            margin: auto;
            background: #fff;
            display: block;
            shape-rendering: auto;
          "
          width="100px"
          height="100px"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
        >
          <circle
            cx="50"
            cy="50"
            r="32"
            stroke-width="8"
            stroke="#7367f0"
            stroke-dasharray="50.26548245743669 50.26548245743669"
            fill="none"
            stroke-linecap="round"
          >
            <animateTransform
              attributeName="transform"
              type="rotate"
              repeatCount="indefinite"
              dur="1s"
              keyTimes="0;1"
              values="0 50 50;360 50 50"
            ></animateTransform>
          </circle>
        </svg>
      </div>
      <div v-else id="scroll-wrapper" class="flex flex-col justify-between">
        <div class="p-8 pt-0">
          <div class="flex flex-wrap justify center pt-2 mb-12">
            <div class="flex items-start w-full">
              <feather-icon icon="TagIcon" svgClasses="w-8 h-8 text-primary" />
              <div class="w-full pb-2 flex flex-wrap">
                <div class="w-full pb-2 flex flex-wrap">
                  <vs-chip
                    v-for="(tag, index) in limitedContactTags"
                    :key="index"
                    class="break-all"
                    closable
                    close-icon="close"
                    @click="removeContactTag(index)"
                  >
                    {{ tag.label | capitalize }}: {{ tag.value | capitalize }}
                  </vs-chip>
                </div>
                <div v-if="viewMore">
                  <div
                    v-for="(conTags, indexConversation) in conversationTags"
                    :key="indexConversation"
                  >
                    <vs-chip
                      v-for="(tag, index) in limitedContactTags"
                      :key="index"
                      class="break-all"
                      closable
                      close-icon="close"
                      @click="removeContactTag(index)"
                    >
                      {{ tag.label | capitalize }}: {{ tag.value | capitalize }}
                    </vs-chip>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex justify-center w-full">
              <vs-button
                v-if="contactTags.length > 5 && !viewMore"
                class="mr-2 rounded-xl"
                type="border"
                size="small"
                @click="toggleViewMore"
              >
                Ver mais
              </vs-button>
              <vs-button
                type="border"
                size="small"
                @click="setShowTagInput(true)"
                class="rounded-xl"
              >
                Adicionar Tag
              </vs-button>
              <div v-if="showTagInput" class="absolute mt-6">
                <form
                  @submit="putContactTag"
                  action="javascript:void(0);"
                  class="w-full shadow p-4 flex items-center"
                  v-click-outside="setShowTagInput"
                >
                  <vs-input
                    maxlength="50"
                    icon="icon-at-sign"
                    icon-pack="feather"
                    style="width: 100px"
                    placeholder="Tag"
                    v-model="newConversationTag"
                    class="no-icon-border mx-1"
                    type="text"
                  ></vs-input>
                  :
                  <vs-input
                    maxlength="140"
                    style="width: 90px"
                    placeholder="Valor"
                    v-model="newConversationTagValue"
                    class="no-icon-border mx-1"
                    type="text"
                  ></vs-input>
                  <vs-button
                    type="filled"
                    size="small"
                    icon="icon-plus"
                    icon-pack="feather"
                    class="mr-4"
                    @click="putContactTag"
                  >
                  </vs-button>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div
          class="px-4 pt-2 pb-2 text-center mb-4"
          v-if="
            !(
              this.$store.state.user.userRole == 'operator' &&
              blockCreateConversationOperator
            )
          "
        >
          <vs-button
            v-if="conversationID === null || closed"
            size="small"
            icon="icon-message-circle"
            icon-pack="feather"
            type="border"
            :href="`/apps/inbox/start-conversation?name=${
              currentContact.name || contactName
            }&phone=${currentContact.phone || contactPhone}`"
            class="justify-center w-full p-3 text-base"
          >
            {{ $t("StartConversation") }}
          </vs-button>
        </div>

        <div class="px-4 pt-2 pb-2 text-center mb-20">
          <h6 class="mb-4 mt-3 strong">{{ $t("LastConversations") }}</h6>
          <div v-if="conversations.length > 0">
            <vx-card
              v-for="(conversation, index) in conversationsPaginationArray"
              :key="index"
              id="about-card"
              @click="openConversationModal(conversation)"
              class="conversation__card no-shadow border border-solid border-grey-light mb-3 cursor-pointer px-3 py-2"
              style="border-radius: 35px"
            >
              <div slot="no-body">
                <div class="flex">
                  <div
                    class="flex flex-col justify-center"
                    style="align-content: center"
                  >
                    <vs-avatar
                      v-if="conversation.channel.includes('chat')"
                      class="border-2 border-solid border-white"
                      :src="require(`@/assets/images/portrait/icons/chat.png`)"
                      size="35px"
                    ></vs-avatar>
                    <vs-avatar
                      v-else-if="conversation.channel.match(/(facebook)/i)"
                      class="border-2 border-solid border-white"
                      :src="
                        require(`@/assets/images/portrait/icons/facebook.png`)
                      "
                      size="35px"
                    ></vs-avatar>
                    <vs-avatar
                      v-else-if="conversation.channel.match(/(instagram)/i)"
                      class="border-2 border-solid border-white"
                      :src="
                        require(`@/assets/images/portrait/icons/instagram.png`)
                      "
                      size="35px"
                    ></vs-avatar>
                    <vs-avatar
                      v-else-if="conversation.channel === 'SMS'"
                      class="border-2 border-solid border-white"
                      :src="require(`@/assets/images/portrait/icons/sms.png`)"
                      size="35px"
                    ></vs-avatar>
                    <vs-avatar
                      v-else
                      class="border-2 border-solid border-white"
                      :src="
                        require(`@/assets/images/portrait/icons/whatsapp.png`)
                      "
                      size="35px"
                    ></vs-avatar>
                  </div>
                  <div class="flex-col w-full">
                    <div class="flex flex-wrap pt-2 pl-1">
                      <div
                        v-for="(intention, index) in treatIntentions(
                          conversation.intentions
                        )"
                        :key="index"
                      >
                        <vs-chip>{{ formatIntentionName(intention) }}</vs-chip>
                      </div>
                    </div>
                    <div class="flex justify-end pr-6">
                      <small class="ml-2">{{
                        formatDate(conversation.createdAt)
                      }}</small>
                    </div>
                  </div>
                </div>
              </div>
            </vx-card>
            <vs-button
              v-if="conversationsPagination < conversations.length"
              type="border"
              style="border-radius: 30px"
              size="small"
              @click="conversationsPagination = conversationsPagination + 3"
            >
              <span style="color: #7367f0">Ver mais</span></vs-button
            >
          </div>
          <div v-else>Nenhuma conversa anterior</div>
        </div>
      </div>
    </VuePerfectScrollbar>
    <div ref="clickOut"></div>
    <!-- Conversation popup -->
    <vs-popup
      :title="`${$t('ConversationHistory')} - ${conversationPopupDateTitle}`"
      :active.sync="conversationPopup"
    >
      <div v-show="!isConversationModalLoading">
        <vs-button
          type="border"
          size="small"
          icon="icon-download"
          icon-pack="feather"
          class="mr-4 p-2 px-3 bg-blac truncate vs-con-loading__container"
          @click="downloadPDF()"
          id="pdfDownloadButton"
        >
          {{ $t("Export") }}
        </vs-button>
        <div class="chat__log" ref="chatLog">
          <VuePerfectScrollbar
            class="contact-tags-scroll-area pt-1"
            :settings="settings"
          >
            <chat-log
              :userId="activeContact"
              :contact="{
                ...$store.state.chat.activeContact,
                status: 'online',
              }"
              :messageListHistory="messageList"
              :contactClosed="true"
              :participants="participants"
              :contactName="contactName"
              @download-message-file="downloadMessageFile"
              @resend-msg="isConversationModalLoading"
            ></chat-log>
          </VuePerfectScrollbar>
        </div>
      </div>
      <div v-show="isConversationModalLoading">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          style="
            margin: auto;
            background: #fff;
            display: block;
            shape-rendering: auto;
          "
          width="100px"
          height="100px"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
        >
          <circle
            cx="50"
            cy="50"
            r="32"
            stroke-width="8"
            stroke="#7367f0"
            stroke-dasharray="50.26548245743669 50.26548245743669"
            fill="none"
            stroke-linecap="round"
          >
            <animateTransform
              attributeName="transform"
              type="rotate"
              repeatCount="indefinite"
              dur="1s"
              keyTimes="0;1"
              values="0 50 50;360 50 50"
            ></animateTransform>
          </circle>
        </svg>
      </div>
    </vs-popup>

    <!-- Popup Bloquear Contato -->
    <vs-popup :active.sync="blockContactPopup" title="Atenção">
      <vs-row>
        <vs-col>
          <p v-if="statusMutedChild === 1" class="text-base">
            Ao bloquear este contato, você não poderá receber ou enviar
            mensagens para este número, até que ele venha a ser desbloqueado na
            página de “Contatos”.<br /><br />

            Deseja prosseguir com o bloqueio?
          </p>
          <p v-else class="text-base">
            Ao desbloquear este contato, você poderá receber ou enviar mensagens
            para este número.<br /><br />

            Deseja prosseguir com o desbloqueio?
          </p>
        </vs-col>
        <vs-col class="mt-8">
          <vs-button
            v-if="statusMutedChild === 1"
            color="danger"
            @click="handleBlockContact(-1)"
            >Sim, bloquear contato</vs-button
          >
          <vs-button v-else color="primary" @click="handleBlockContact(1)"
            >Sim, desbloquear contato</vs-button
          >
        </vs-col>
      </vs-row>
    </vs-popup>

    <vs-popup :title="`Opt-In ${contactName}`" :active.sync="lgpdPopUp">
      <vs-row>
        <vs-col>
          Confirmar a permissão de contato para recebimentos de mensagens
          ativas.
        </vs-col>
        <vs-col class="mt-4" vs-type="flex" vs-justify="end">
          <vs-button
            type="border"
            color="success"
            @click="updateContact('optin'), (lgpdPopUp = false)"
          >
            Confirmar
          </vs-button>
          <vs-button class="ml-4" type="border" @click="lgpdPopUp = false"
            >Cancelar</vs-button
          >
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>

<script>
import axios from "@/axios";
import { AsYouType, parsePhoneNumber } from "libphonenumber-js";
import vClickOutside from "v-click-outside";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import ChatLog from "../../views/apps/inbox/ChatLog.vue";
import ChatPdfTemplate from "../../views/apps/inbox/ChatPdfTemplate.vue";
import Vue from "vue"; // Import Vue
import html2pdf from "html2pdf.js";

export default {
  name: "ContactProfile",
  props: {
    contactID: {
      type: String,
      required: true,
    },
    conversationID: {
      type: [String, null],
    },
    fromInbox: {
      type: Boolean,
      default: false,
    },
    contactName: {
      type: String,
    },
    contactEmail: {
      type: String,
    },
    contactPhone: {
      type: String,
    },
    contactPhase: {
      type: String,
    },
    contactProfilePic: {
      type: String,
    },
    contactOptIn: {
      type: Boolean,
    },
    phaseList: {
      type: Array,
      required: true,
    },
    closed: {
      type: Boolean,
    },
    activeContact: {
      type: String,
      required: true,
    },
    showLoading: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    status: {
      type: Number,
    },
  },
  watch: {
    activeContact: function () {
      this.viewMore = false;
      this.lastConversationsLoading = this.showLoading;
      this.getConversationsHistory();
      this.getContactTags();
      this.conversationsPagination = 3;
    },
    contactId: function () {
      this.currentContact.id = this.contactId;
    },
    contactName: function () {
      this.currentContact.name = this.contactName;
    },
    contactEmail: function () {
      this.currentContact.email = this.contactEmail;
    },
    contactPhone: function () {
      this.currentContact.phone = this.contactPhone;
    },
    contactPhase: function () {
      this.currentContact.phase = this.contactPhase;
    },
    conversations: function (conversations) {
      let allConversations = [...conversations, ...this.activeConversations];
      allConversations = allConversations.filter(
        (conversation) => conversation.tags.length > 0
      );
      this.conversationTags = allConversations.map((conversation) => {
        return { inboxConversation: conversation._id, tags: conversation.tags };
      });
    },
  },
  mounted() {
    this.$http
      .get(`/p/chat/inbox/${this.$store.state.acc.current_acc.id}`, {
        params: { acc: this.$store.state.acc.current_acc.id },
      })
      .then(async (response) => {
        const data = response.data.data;
        this.blockCreateConversationOperator =
          !!data.inbox.blockCreateConversationOperator;
      });
  },
  data() {
    return {
      viewMore: false,
      currentContact: {},
      counterDanger: false,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
      showIconName: false,
      showIconPhone: false,
      showIconEmail: false,
      showNameInput: false,
      showPhoneInput: false,
      showEmailInput: false,
      showTagInput: false,
      newConversationTag: "",
      newConversationTagValue: "",
      newPhase: "",
      showRemoveTag: null,
      conversations: [],
      activeConversations: [],
      conversationsPagination: 3,
      conversationPopupDateTitle: "",
      conversationPopup: false,
      oldConversationPopup: false,
      isConversationModalLoading: false,
      lastConversationsLoading: true,
      getConversationsHistoryTimer: null,
      messageList: [],
      participants: [],
      actualConversationId: "",
      conversationTags: [],
      contactTags: [],
      validPhone: true,
      newContactPhoneProp: "",
      blockContactPopup: false,
      statusChild: null,
      blockCreateConversationOperator: true,
      optIn: this.contactOptIn,
      lgpdPopUp: false,
      conversation: {},
    };
  },
  computed: {
    limitedContactTags() {
      return this.viewMore ? this.contactTags : this.contactTags.slice(0, 5);
    },
    contactNameInitials() {
      const initials = this.currentContact.name
        ? this.currentContact.name.match(/\b\w/g) || []
        : this.contactName.match(/\b\w/g) || [];
      return ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();
    },
    phase: {
      get() {
        return this.contactPhase;
      },
      set(value) {
        const oldPhase = this.contactPhase;
        this.currentContact.phase = value;
        this.newPhase = value;
        this.updateContact("phase", oldPhase);
      },
    },
    conversationsPaginationArray() {
      return this.conversations.slice(0, this.conversationsPagination);
    },
    statusMutedChild: {
      get() {
        return this.statusChild || this.status;
      },
      set(val) {
        this.statusChild = val;
      },
    },
    isAdmin() {
      return (
        this.$store.state.user.userRole === "admin" ||
        this.$store.state.user.userRole === "master" ||
        this.$store.state.user.userRole === "manager"
      );
    },
  },
  created() {
    this.getConversationsHistory();
    this.getContactTags();
    this.lastConversationsLoading = this.showLoading;
    this.conversationsPagination = 3;
  },
  methods: {
    toggleViewMore() {
      this.viewMore = true;
    },
    toDateSmall(time) {
      let fullDate = new Date(time);
      let valid = new Date(time).getTime() > 0;
      if (!valid) fullDate = new Date(time);
      const dd = String(fullDate.getDate()).padStart(2, "0");
      const mm = String(fullDate.getMonth() + 1).padStart(2, "0");
      const yy = String(fullDate.getFullYear()).substring(2, 4);
      let hour = fullDate.getHours() + ":";
      if (fullDate.getMinutes() < 10) hour += "0" + fullDate.getMinutes();
      else hour += fullDate.getMinutes();
      return `${dd}/${mm}/${yy} - ${hour}`;
    },
    async getContactTags() {
      try {
        const res = await axios({
          method: "get",
          url: `/p/chat/contact/tag/${this.contactID}`,
        });

        this.contactTags = res.data.data;
      } catch (e) {
        let errorMessage = this.$t("UnexpectedError");

        if (e.response && e.response.data && e.response.data.message)
          errorMessage = e.response.data.message;

        this.$vs.notify({
          title: this.$t("Error"),
          text: errorMessage,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right",
          time: 4000,
        });
      }
    },
    setShowNameInput(val) {
      const newContactName = this.$refs.newContactName;
      if (val === false) newContactName.value = "";
      this.showNameInput = val;
      if (val === true) {
        newContactName.value = this.currentContact.name || this.contactName;
        setTimeout(() => newContactName.focus(), 1);
      }
    },
    setShowPhoneInput(val) {
      if (val.type) val = false;
      if (val === false) {
        this.showIconPhone = false;
      }
      this.validPhone = true;
      this.showPhoneInput = val;
      const self = this;
      if (val === true) {
        setTimeout(() => {
          const newContactPhone = self.$refs.newContactPhone;
          let phone = "";
          if (this.currentContact && this.currentContact.phone) {
            phone = this.currentContact.phone;
          } else if (this.contactPhone) {
            phone = this.contactPhone;
          }
          phone = String(phone).replace(/\D/g, "");
          if (phone.length < 9 && phone.length > 7 && !phone.startsWith("55"))
            phone = `55${phone}`;
          phone = `+${phone}`;
          self.newContactPhoneProp = phone;
          newContactPhone.focus(), 1;
        });
      }
    },
    setShowEmailInput(val) {
      const newContactEmail = this.$refs.newContactEmail;
      if (val === false) {
        this.showIconEmail = false;
      }
      this.showEmailInput = val;
      if (val === true) {
        newContactEmail.value =
          this.currentContact.email || this.contactEmail || "";
        setTimeout(() => newContactEmail.focus(), 1);
      }
    },
    setShowTagInput(val) {
      if (typeof val === "object") val = false;
      if (val === false) {
        this.newConversationTag = "";
        this.newConversationTagValue = "";
      } else {
        window.analytics.track(
          "Add Tag Contato",
          {},
          { groupId: this.$store.state.acc.current_acc.id }
        );
      }
      this.showTagInput = val;
    },
    findLastChannelInMessagesList(messageList, activeContact) {
      const finder = (index) => {
        if (messageList[index - 1] === undefined) return activeContact.channel;
        else if (messageList[index - 1].author !== "system")
          return messageList[index - 1].channel;
        else return finder(index - 1);
      };

      return finder(messageList.length);
    },
    updateContact(field, oldPhase) {
      const contactID = this.contactID;
      let newContactField;
      switch (field) {
        case "phase":
          newContactField = { phase: this.newPhase };
          break;
        case "name":
          {
            newContactField = {
              name: this.$refs.newContactName.value,
            };
            if (this.fromInbox) {
              const channel = this.findLastChannelInMessagesList(
                this.conversationsPaginationArray,
                this.activeContact
              );
              newContactField.fromInbox = true;
              newContactField.channel = channel;
              newContactField.sessionID =
                this.$store.state.chat.activeContact.sessionID;
              newContactField.conversationID = this.conversationID;
            }
          }
          break;
        case "phone":
          {
            newContactField = {
              phone: this.newContactPhoneProp,
            };
            if (this.fromInbox) {
              const channel = this.findLastChannelInMessagesList(
                this.conversationsPaginationArray,
                this.activeContact
              );
              newContactField.fromInbox = true;
              newContactField.channel = channel;
              newContactField.sessionID =
                this.$store.state.chat.activeContact.sessionID;
              newContactField.conversationID = this.conversationID;
            }
          }
          break;
        case "email":
          {
            newContactField = {
              email: this.$refs.newContactEmail.value,
            };
            if (this.fromInbox) {
              const channel = this.findLastChannelInMessagesList(
                this.conversationsPaginationArray,
                this.activeContact
              );
              newContactField.fromInbox = true;
              newContactField.channel = channel;
              newContactField.sessionID =
                this.$store.state.chat.activeContact.sessionID;
              newContactField.conversationID = this.conversationID;
            }
          }
          break;
        case "optin":
          newContactField = { optIn: !this.optIn };
          break;
        default:
          return;
      }

      if (field === "phone")
        newContactField.phone = newContactField.phone.match(/\d+/g).join("");

      if (field !== "phone" || (field === "phone" && this.validPhone)) {
        axios({
          method: "put",
          url: `/p/chat/contact/${contactID}`,
          data: {
            acc: this.$store.state.acc.current_acc.id,
            conversationSession: this.conversationID,
            data: { ...newContactField },
          },
        })
          .then(() => {
            for (var i in newContactField) {
              let update = {
                _id: this.contactID,
                prop: i,
                value: newContactField[i],
              };
              this.$emit("update-contact", update, oldPhase);
              this.currentContact[i] = newContactField[i];
              this.newPhase = null;
              this.$refs.newContactName.click();
              this.$refs.clickOut.click();
            }

            if (field === "optin") this.optIn = !this.optIn;

            this.showNameInput = false;
            this.showPhoneInput = false;
            this.showEmailInput = false;

            this.$vs.notify({
              title: this.$t("Success"),
              text: "Contato alterado com sucesso.",
              iconPack: "feather",
              icon: "icon-success-circle",
              color: "success",
              position: "top-right",
              time: 4000,
            });
          })
          .catch((err) => {
            const res = JSON.parse(err.request.response);
            if (
              Object.prototype.hasOwnProperty.call(newContactField, "phone") &&
              res.message === "Already exists"
            ) {
              this.$vs.notify({
                title: this.$t("UnexpectedError"),
                text: "Telefone já cadastrado",
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "warning",
                position: "top-right",
                time: 4000,
              });
            } else if (
              Object.prototype.hasOwnProperty.call(newContactField, "email") &&
              res.message === "Already exists"
            ) {
              this.$vs.notify({
                title: this.$t("UnexpectedError"),
                text: "Email já cadastrado",
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "warning",
                position: "top-right",
                time: 4000,
              });
            } else {
              this.$vs.notify({
                title: this.$t("UnexpectedError"),
                text: "Erro, favor verificar os dados",
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "warning",
                position: "top-right",
                time: 4000,
              });
            }
          });
      } else {
        this.$vs.notify({
          time: 5000,
          title: this.$t("Error"),
          text: this.$t("VerifyDataAndTryAgain"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right",
        });
      }
    },
    putContactTag() {
      const newTag = `@${this.newConversationTag}`;
      const newValue = this.newConversationTagValue;

      axios
        .post(`/p/chat/contact/tag/${this.contactID}`, {
          data: { label: newTag, value: newValue },
        })
        .then(() => {
          this.contactTags.push({ label: newTag, value: newValue });
          this.newConversationTag = "";
          this.newConversationTagValue = "";
          this.showTagInput = false;

          this.$vs.notify({
            title: this.$t("Success"),
            text: "Tag adicionada com sucesso",
            iconPack: "feather",
            icon: "icon-success-circle",
            color: "success",
            position: "top-right",
            time: 4000,
          });
        })
        .catch((e) => {
          let errorMessage = this.$t("UnexpectedError");

          if (e.response && e.response.data && e.response.data.message)
            errorMessage = e.response.data.message;

          this.$vs.notify({
            title: this.$t("Error"),
            text: errorMessage,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
            position: "top-right",
            time: 4000,
          });
        });
    },
    putConversationTag() {
      const newConversationTag = `@${this.newConversationTag}`;
      const newConversationTagValue = this.newConversationTagValue;
      const conversationID = this.conversationID;
      axios({
        method: "post",
        url: `/p/chat/conversation/tag/${conversationID}`,
        data: {
          data: { label: newConversationTag, value: newConversationTagValue },
        },
      })
        .then(() => {
          let allTags;
          let conversationIndex = this.conversationTags.findIndex(
            (conversation) => conversation.inboxConversation === conversationID
          );
          const conversationTags = JSON.parse(
            JSON.stringify(this.conversationTags)
          );
          if (conversationIndex === -1) {
            conversationIndex = this.activeConversations.findIndex(
              (conversation) => conversation._id === conversationID
            );
            this.activeConversations[conversationIndex].tags.push({
              label: newConversationTag,
              value: newConversationTagValue,
            });
            conversationTags[conversationIndex].tags.push({
              label: newConversationTag,
              value: newConversationTagValue,
            });
            allTags = this.activeConversations.flatMap(
              (contactTag) => contactTag.tags
            );
          } else {
            conversationTags[conversationIndex].tags.push({
              label: newConversationTag,
              value: newConversationTagValue,
            });
            allTags = this.conversationTags.flatMap(
              (contactTag) => contactTag.tags
            );
          }

          this.conversationTags = conversationTags;

          let update = {
            _id: this.contactID,
            prop: "tags",
            value: allTags,
          };

          this.$emit("update-contact", update);
          this.newConversationTag = "";
          this.newConversationTagValue = "";
          this.showTagInput = false;
          this.$vs.notify({
            title: this.$t("Success"),
            text: "Tag adicionada com sucesso",
            iconPack: "feather",
            icon: "icon-success-circle",
            color: "success",
            position: "top-right",
            time: 4000,
          });
        })
        .catch(() => {
          this.$vs.notify({
            title: this.$t("UnexpectedError"),
            text: "Erro",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "warning",
            position: "top-right",
            time: 4000,
          });
        });
    },
    setShowRemoveTag(index) {
      this.showRemoveTag = index;
    },
    showRemoveTagVerification(index) {
      let verification;
      this.showRemoveTag === index
        ? (verification = true)
        : (verification = false);
      return verification;
    },
    removeContactTag(index) {
      axios({
        method: "delete",
        url: `/p/chat/contact/tag/${this.contactID}`,
        data: {
          data: { index },
        },
      })
        .then(() => {
          this.contactTags.splice(index, 1);

          this.$vs.notify({
            title: this.$t("Success"),
            text: "Tag removida com sucesso",
            iconPack: "feather",
            icon: "icon-success-circle",
            color: "success",
            position: "top-right",
            time: 4000,
          });
        })
        .catch(() => {
          this.$vs.notify({
            title: this.$t("Error"),
            text: "Erro",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
            position: "top-right",
            time: 4000,
          });
        });
    },
    removeConversationTag(index, inboxConversation, inboxConversationIndex) {
      axios({
        method: "delete",
        url: `/p/chat/conversation/tag/${inboxConversation}`,
        data: {
          data: { index },
        },
      })
        .then(() => {
          const conversationTags = JSON.parse(
            JSON.stringify(this.conversationTags)
          );
          conversationTags[inboxConversationIndex].tags.splice(index, 1);

          this.conversationTags = conversationTags;

          const allTags = conversationTags.flatMap(
            (contactTag) => contactTag.tags
          );

          let update = {
            _id: this.contactID,
            prop: "tags",
            value: allTags,
          };

          this.$emit("update-contact", update);

          this.$vs.notify({
            title: this.$t("Success"),
            text: "Tag removida com sucesso",
            iconPack: "feather",
            icon: "icon-success-circle",
            color: "success",
            position: "top-right",
            time: 4000,
          });
        })
        .catch(() => {
          this.$vs.notify({
            title: this.$t("Error"),
            text: "Erro",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
            position: "top-right",
            time: 4000,
          });
        });
    },
    getConversationsHistory() {
      if (this.getConversationsHistoryTimer)
        clearTimeout(this.getConversationsHistoryTimer);
      this.getConversationsHistoryTimer = setTimeout(() => {
        axios({
          method: "get",
          url: `/p/chat/conversations/${this.contactID}`,
        }).then((response) => {
          this.conversations = response.data.data.filter(
            (conversation) => conversation.status === 4
          );
          this.activeConversations = response.data.data.filter(
            (conversation) => conversation.status === 1
          );
          this.lastConversationsLoading = false;
        });
      }, 1000);
    },
    openConversationModal(conversation) {
      this.conversation = conversation;
      this.isConversationModalLoading = true;
      this.conversationPopupDateTitle = this.formatDate(conversation.createdAt);
      this.actualConversationId = conversation._id;
      const acc = this.$store.state.acc.current_acc.id;
      const id = this.actualConversationId;
      this.conversationPopup = true;

      axios({
        method: "get",
        url: `/p/chat/conversation/messages/${acc}/${id}`,
      }).then((response) => {
        const history = response.data.data;
        if (history.messages !== undefined) {
          this.messageList = history.messages.map((messageItem) => {
            if (
              messageItem.type === "file" &&
              messageItem.data.file.includes("s3.amazonaws")
            ) {
              messageItem.data.fileStatus = 0;
              messageItem.data.file = "";
            }
            return messageItem;
          });
        }
        if (
          typeof history.participants === "object" &&
          history.participants.length > 0
        )
          this.participants = history.participants;
        this.isConversationModalLoading = false;
      });
    },
    b64toUrl(b64Data, contentType, sliceSize) {
      contentType = contentType || "";
      sliceSize = sliceSize || 512;

      var byteCharacters = atob(b64Data);
      var byteArrays = [];

      for (
        var offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);
        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      var blob = new Blob(byteArrays, { type: contentType });
      return URL.createObjectURL(blob);
    },
    updateMessageOnMessageList({ messageID, payload }) {
      const compareMessage = (messageItem) => {
        return messageItem.id === messageID;
      };
      const messageFoundedIndex = this.messageList.findIndex(compareMessage);
      this.messageList[messageFoundedIndex] = {
        ...this.messageList[messageFoundedIndex],
        ...payload,
      };
      this.messageList = Object.assign([], this.messageList);
    },
    downloadMessageFile(status, message) {
      this.updateMessageOnMessageList({
        messageID: message.id,
        payload: { data: { ...message.data, fileStatus: status } },
      });

      axios({
        method: "post",
        url: `/p/chat/downloadMessageFile`,
        data: {
          getURL: true,
          messageID: message.id,
          sessionID: this.$store.state.chat.activeContact.sessionID,
          chatID: this.$store.state.chat.activeContact.chatID,
          userID: this.$store.state.chat.userProfileId,
          inboxConversationId: this.actualConversationId,
        },
      })
        .then((res) => {
          const response = JSON.parse(res.request.response);
          const { data } = response;
          this.updateMessageOnMessageList({
            messageID: message.id,
            payload: {
              data: { ...message.data, fileStatus: 1, fileURL: data.url },
            },
          });
          return data.url;
        })
        .catch(() => {
          this.$vs.notify({
            title: this.$t("Error"),
            text: "Erro",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
            position: "top-right",
            time: 4000,
          });
        });
    },
    formatDate(date) {
      const data = new Date(date),
        dia = data.getDate().toString(),
        diaF = dia.length === 1 ? "0" + dia : dia,
        mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
        mesF = mes.length === 1 ? "0" + mes : mes,
        anoF = data.getFullYear();
      return diaF + "/" + mesF + "/" + anoF;
    },
    formatIntentionName(intention) {
      if (
        !intention ||
        typeof intention !== "string" ||
        intention.trim().length === 0
      ) {
        return this.$t("Others");
      }
      return `${intention.charAt(0).toUpperCase()}${intention
        .slice(1)
        .toLowerCase()}`;
    },
    treatIntentions(intentions) {
      if (intentions.length === 0) intentions.push("outros");
      return intentions;
    },
    validatePhone(e) {
      this.validPhone = true;
      if (
        this.contactPhone &&
        typeof this.contactPhone === "string" &&
        this.contactPhone.slice(0, 2) === "55"
      ) {
        this.validPhone = e.valid;
      }
    },
    formatPhone(phone) {
      if (phone === undefined || phone === "" || phone === null) return phone;

      try {
        if (phone.startsWith("55")) {
          const isLongNumber = phone.length === 13;
          return (
            "+" +
            phone.slice(0, 2) +
            " (" +
            phone.slice(2, 4) +
            ") " +
            phone.slice(4, isLongNumber ? 9 : 8) +
            "-" +
            phone.slice(isLongNumber ? 9 : 8)
          );
        }
        const parsedNumber = parsePhoneNumber("+" + phone);

        if (
          !parsedNumber ||
          !parsedNumber.country ||
          !parsedNumber.nationalNumber
        ) {
          throw new Error("Invalid parsed phone number");
        }

        return (
          "+" +
          parsedNumber.countryCallingCode +
          " " +
          new AsYouType(parsedNumber.country).input(parsedNumber.nationalNumber)
        );
      } catch (error) {
        return phone; // Return the original phone as a fallback
      }
    },
    formatEmail(email) {
      return email.length > 25 ? `${email.slice(0, 25)}...` : email;
    },
    popupBlockContact() {
      this.blockContactPopup = true;
    },
    async handleBlockContact(newStatus) {
      if (newStatus === -1)
        window.analytics.track(
          "Bloquear Contato",
          {},
          { groupId: this.$store.state.acc.current_acc.id }
        );
      this.$emit("close-profile-sidebar", false);
      this.blockContactPopup = false;

      const response = await this.$http.put(
        "/p/chat/contact/" + this.contactID,
        {
          acc: this.$store.state.acc.current_acc.id,
          data: {
            status: newStatus,
          },
        }
      );

      if (response.status === 200) {
        this.statusMutedChild = newStatus;

        if (this.statusMutedChild === -1) {
          this.$emit("close-conversation", {
            id: 3,
            name: "Spam",
            canDelete: false,
            typeSearch: "Nenhum",
            subReasons: [],
          });
        }

        this.$vs.notify({
          title: this.$t("Success"),
          text: newStatus === -1 ? "Contato bloqueado" : "Contato desbloqueado",
          iconPack: "feather",
          icon: newStatus === -1 ? "icon-user-x" : "icon-user-check",
          color: "success",
          position: "top-right",
          time: 4000,
        });

        this.$emit("update-contact", {
          _id: this.contactID,
          prop: "status",
          value: this.statusMutedChild,
        });
      } else {
        this.$vs.notify({
          title: this.$t("UnexpectedError"),
          text: "Erro ao bloquear contato",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning",
          position: "top-right",
          time: 4000,
        });
      }
    },
    onClickContactPhase(phaseVal) {
      this.phase = phaseVal;
      window.analytics.track(
        "Alterar Status Contato",
        {},
        { groupId: this.$store.state.acc.current_acc.id }
      );
    },
    downloadPDF() {
      this.$vs.loading({
        container: "#pdfDownloadButton",
        scale: 0.45,
      });
      setTimeout(() => {
        this.$vs.loading.close("#pdfDownloadButton > .con-vs-loading");
      }, 3000);
      try {
        const ChatPdfConstructor = Vue.extend(ChatPdfTemplate);
        const fileName = `${
          this.$store.state.chat.activeContact.name.split(" ")[0]
        }-${this.$store.state.chat.activeContact.chatID}.pdf`;
        const chatInstance = new ChatPdfConstructor({
          store: this.$store,
          propsData: {
            contact: this.$store.state.chat.activeContact,
            messageListHistory: this.messageList,
            API_URL: process.env.VUE_APP_API_URL,
          },
        }).$mount(); // Mount it in memory

        // Get the rendered HTML of the component
        const htmlContent = chatInstance.$el;

        // Use html2pdf to generate and download the PDF
        const options = {
          margins: [5, 5, 5, 5],
          filename: fileName,
          image: { type: "jpeg", quality: 0.8 },
          html2canvas: { scale: 1.5 },
          jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
          pagebreak: { mode: "avoid-all" },
        };

        html2pdf().from(htmlContent).set(options).save();
        this.$vs.loading.close("#pdfDownloadButton > .con-vs-loading");
      } catch (error) {
        this.$vs.loading.close("#pdfDownloadButton > .con-vs-loading");
      }
    },
  },
  components: {
    VuePerfectScrollbar,
    ChatLog,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
};
</script>
<style scoped>
.header-sidebar {
  padding-top: 0.5rem !important;
}
#about-card .vx-card__body {
  padding: 0 !important;
}
.conversation__card:hover {
  opacity: 0.7 !important;
  background-color: rgba(141, 141, 141, 0.055);
}
#contactName:hover {
  color: #999;
  cursor: pointer;
}
.showIconClass svg {
  width: 16px;
  transition: all 0.3s ease;
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
}
.showInputInitialClass {
  display: none;
}
.showInputClass {
  display: block;
}
.contact-tags-scroll-area {
  padding: 30px 0 30px 0;
  max-height: calc(100vh - 200px) !important;
}
</style>
