<template>
  <div id="teamChatFrame" v-if="loadChat">
    <div>
      <button
        class="rounded-full h-12 w-12 flex items-center justify-center text-white cursor-pointer border-none"
        id="team-chat-buttom"
        style="background: rgba(var(--vs-primary), 1); cursor: pointer"
        @click="toogleTeamChat()"
        v-if="!teamChatIsVisible"
      >
        <span class="material-icons-outlined cursor-pointer"> people </span>
      </button>
    </div>
    <div class="d-flex" id="notification-bade" v-if="!teamChatIsVisible">
      <div class="notification-badge" v-if="notificationSum > 0">
        {{ notificationSum > 100 ? "99+" : notificationSum }}
      </div>
    </div>

    <div class="w-full hidden" id="teamChat">
      <div
        class="flex p-3 items-center justify-center"
        style="background: rgba(var(--vs-primary), 1) !important"
      >
        <div class="absolute right-0 mr-3">
          <button
            class="rounded-full h-6 w-6 flex items-center justify-center border-none cursor-pointer"
            style="background-color: white"
            @click="toogleTeamChat()"
          >
            <span class="material-icons-outlined cursor-pointer text-xs">
              close
            </span>
          </button>
        </div>
        <div class="text-white">Chat Interno - Beta</div>
      </div>
      <div style="border: solid 1px #f4f4f4">
        <vue-advanced-chat
          :height="chatHeight"
          :current-user-id="currentUserId"
          :rooms="JSON.stringify(rooms)"
          :rooms-loaded="true"
          :messages="JSON.stringify(messages)"
          :messages-loaded="messagesLoaded"
          @send-message="sendMessage($event.detail[0])"
          @fetch-messages="fetchMessages($event.detail[0])"
          @open-file="openFile($event.detail[0])"
          @toggle-rooms-list="toggleRoomsList($event.detail[0])"
          @menu-action-handler="menuActionHandler($event.detail[0])"
          :menu-actions="menuActions"
          show-reaction-emojis="false"
          show-add-room="false"
          show-audio="false"
          show-new-messages-divider="false"
          user-tags-enabled="false"
          media-preview-enabled="false"
          message-actions="[]"
          :responsive-breakpoint="breakpoint"
          accepted-files="image/png,
        image/jpeg, application/pdf, application/doc, application/docx,
        application/xlsx"
          :text-messages="JSON.stringify(textMessages)"
          rooms-order="asc"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { register } from "vue-advanced-chat";
import socketTC from "./socketTeamChat";
register();

export default {
  data() {
    return {
      //   currentUserId: this.currentUserId, // userId
      currentRoom: null,
      notificationSum: 0,
      teamChatIsVisible: false,
      rooms: [],
      messages: [],
      messagesLoaded: false,
      breakpoint: 3000,
      menuActions: null,
      baseMenuActions: [{ name: "clearMessages", title: "Limpar mensagens" }],
      textMessages: {
        ROOMS_EMPTY: "Sem salas",
        ROOM_EMPTY: "Sala vazia",
        NEW_MESSAGES: "Nova mensagem",
        MESSAGE_DELETED: "Mensagem deletada",
        MESSAGES_EMPTY: "Sem mensagens",
        CONVERSATION_STARTED: "Conversa iniciou em:",
        TYPE_MESSAGE: "Digite sua mensagem",
        SEARCH: "Pesquise",
        IS_ONLINE: "Online",
        LAST_SEEN: "Última vez visto: ",
        IS_TYPING: "Digitando",
        CANCEL_SELECT_MESSAGE: "Cancelar mensagem selecionada",
      },
      show: true,
      loadChat: false,
      isUserActive: true,
      activityTimeout: null,
      activityEvents: [
        "mousedown",
        "mousemove",
        "keydown",
        "scroll",
        "touchstart",
        "click",
      ],
      idleTime: 5 * 60 * 1000, // 10 minutes
      pingIntervalTime: 2 * 60 * 1000, // 5 minute
      pingInterval: null,
      currentAccId: null,
    };
  },
  created() {
    this.resetActivityTimeout = this.resetActivityTimeout.bind(this);
  },
  beforeDestroy() {
    this.removeActivityListeners();
    this.clearActivityTimeout();
    this.clearPingInterval();
  },
  computed: {
    currentUserId() {
      if (!this.$store.state.user) return false;
      return this.$store.state.user._id;
    },
    chatHeight() {
      return screen.availWidth <= 640 ? "100vh" : "calc(100vh - 42px)";
    },
  },
  watch: {
    "$store.state.chat.restrictOperator"(val) {
      if (val) this.loadChat = false;
    },
    "$store.state.acc.current_acc": {
      handler(newVal) {
        if (newVal && typeof newVal.id === "string" && newVal.id.length) {
          this.currentAccId = newVal.id;
          this.connectSocket();
        }
      },
      immediate: true, // Executa o handler imediatamente para o valor atual
    },
    teamChatIsVisible(val) {
      if (val) {
        this.$store.dispatch("chat/setTeamChatOpen", true);
      } else {
        this.$store.dispatch("chat/setTeamChatOpen", false);
      }
    },
    currentRoom(val) {
      const room = this.rooms.find((room) => room.roomId === val);

      if (room && room.roomName === "Duotalk IA") {
        this.menuActions = JSON.stringify(this.baseMenuActions);
      } else {
        this.menuActions = null;
      }
    },
  },
  methods: {
    initActivityListeners() {
      this.activityEvents.forEach((event) => {
        window.addEventListener(event, this.resetActivityTimeout, true);
      });
      // document.addEventListener('visibilitychange', this.handleVisibilityChange);
    },
    removeActivityListeners() {
      this.activityEvents.forEach((event) => {
        window.removeEventListener(event, this.resetActivityTimeout, true);
      });
      // document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    },
    resetActivityTimeout() {
      this.isUserActive = true;
      this.clearActivityTimeout();
      this.activityTimeout = setTimeout(() => {
        this.userIsIdle();
      }, this.idleTime);
    },
    clearActivityTimeout() {
      if (this.activityTimeout) {
        clearTimeout(this.activityTimeout);
        this.activityTimeout = null;
      }
    },
    userIsIdle() {
      this.isUserActive = false;
      socketTC.emit("pingOffline");
    },
    // handleVisibilityChange() {
    //   if (document.hidden) {
    //     this.userIsIdle();
    //   } else {
    //     this.resetActivityTimeout();
    //   }
    // },
    startPingInterval() {
      this.pingInterval = setInterval(() => {
        try {
          if (this.isUserActive) {
            socketTC.emit("pingLastSeen");
          } else {
            socketTC.emit("pingOffline");
          }
        } catch (error) {
          //catch
        }
      }, this.pingIntervalTime);
    },
    clearPingInterval() {
      if (this.pingInterval) {
        clearInterval(this.pingInterval);
        this.pingInterval = null;
      }
    },
    connectSocket() {
      this.loadChat = true;
      socketTC.auth = {
        accID: this.currentAccId,
        userID: this.currentUserId,
        token: this.$store.state.auth.accessToken,
        platform: "web",
      };

      socketTC.connect();

      this.initSocket();
    },
    menuActionHandler({ action, roomId }) {
      switch (action.name) {
        case "clearMessages":
          return this.clearMessages(roomId);
      }
    },
    clearMessages(roomId) {
      socketTC.emit("clearMessages", {
        roomId: roomId,
      });

      this.messages = [];
    },
    toogleTeamChat() {
      if (!this.teamChatIsVisible)
        window.analytics.track(
          "Clique no TeamChat",
          {},
          { groupId: this.$store.state.acc.current_acc.id }
        );
      this.teamChatIsVisible = !this.teamChatIsVisible;
      const e = document.getElementById("teamChat");
      const e2 = document.getElementById("teamChatFrame");
      const body = document.querySelector("body");
      const notificationBade = document.getElementById("notification-bade");

      if (this.teamChatIsVisible) {
        e2.classList.add("chatOpen");
        e.classList.remove("hidden");
        if (notificationBade) notificationBade.classList.add("hidden");
        if (screen.availWidth <= 640) body.style.overflow = "hidden";
      } else {
        e2.classList.remove("chatOpen");
        e.classList.add("hidden");
        if (notificationBade) notificationBade.classList.remove("hidden");
        if (screen.availWidth <= 640) body.style.overflow = "auto";
      }
    },
    initSocket() {
      socketTC.on("connect", () => {
        socketTC.on("teamMembers", ({ teamMembers, tcContacts }) => {
          this.initActivityListeners();
          this.resetActivityTimeout();
          this.startPingInterval();
          const rooms = [];
          for (let i = 0; i < teamMembers.length; i++) {
            if (!teamMembers[i].user) continue;
            // Verifica no array de tcContacts o registro com mesmo id ao InboxOperator, para pegar o número de mensagens não vistas
            const contactUnseen = tcContacts.find(
              (teamMember) => teamMember.teamOperator === teamMembers[i]._id
            );

            // Faz um contador par mensagens não vista
            this.notificationSum = contactUnseen
              ? contactUnseen.tcMessagesUnseen + this.notificationSum
              : this.notificationSum;

            rooms.push({
              roomId: teamMembers[i]._id, // utiliza o InboxOperator para o _id
              roomName: teamMembers[i].user._id
                ? teamMembers[i].user._id == this.currentUserId
                  ? `${teamMembers[i].user.name} (Você)`
                  : teamMembers[i].user.name
                : "Duotalk IA",
              avatar: teamMembers[i].user._id
                ? require("@/assets/images/portrait/icons/outline_account_circle_black_24dp.png")
                : require("@/assets/images/portrait/icons/logo-chatgpt.jpg"),
              // unreadCount: () ? '' : 0, // mensagens não lidas
              unreadCount: contactUnseen ? contactUnseen.tcMessagesUnseen : 0, // mensagens não lidas
              users: [
                {
                  _id: teamMembers[i]._id,
                  username: teamMembers[i].user.name || "Duotalk IA",
                },
              ],
              index: teamMembers[i].user._id
                ? teamMembers[i].user._id == this.currentUserId
                  ? "1"
                  : teamMembers[i].user.name
                : "0",
            });
          }
          this.rooms = rooms;
          setTimeout(() => {
            const chatElements =
              document.getElementsByTagName("vue-advanced-chat");
            if (chatElements && chatElements[0] && chatElements[0].shadowRoot) {
              const shadowRoot = chatElements[0].shadowRoot;
              const shadowChildNodes = shadowRoot.childNodes;

              if (shadowChildNodes && shadowChildNodes[1]) {
                const targetNode = shadowChildNodes[1];
                const roomItems =
                  targetNode.getElementsByClassName("vac-room-item");

                if (roomItems && roomItems.length > 0) {
                  roomItems[0].setAttribute(
                    "style",
                    "border: 1px #ffd500 solid; background-color: #fffced"
                  );
                }
              }

              if (shadowChildNodes && shadowChildNodes[0]) {
                const cssNode = shadowChildNodes[0];
                if (cssNode.innerHTML) {
                  cssNode.innerHTML = cssNode.innerHTML.replace(
                    ".vac-message-box{display:flex;flex:0 0 50%;max-width:50%;justify-content:flex-start;line-height:1.4}",
                    ".vac-message-box{display:flex;flex:0 0 90%;max-width:90%;justify-content:flex-start;line-height:1.4}"
                  );
                }
              }
            }
          }, 1000);
        });
        // Recebe a mensagem enviada, que teve o trigger com a função sendMessage()
        socketTC.on("sendMessage", (message) => {
          if (this.currentRoom == message.operator) {
            socketTC.emit("updateUnreadCount", {
              roomId: this.currentRoom,
              unreadCount: 0,
            });
          }
          this.messages = [...this.messages, ...this.addMessages([message])];
        });
        // Recebe o histórico de mensagens, que teve o trigger com a função fetchMessages()
        socketTC.on("fetchMessages", (data) => {
          this.currentRoom = data.roomId;

          for (let i = 0; i < data.messages.length; i += 1) {
            const curentMessage = data.messages[i];
            if (curentMessage.files) {
              for (let j = 0; j < curentMessage.files.length; j += 1) {
                data.messages[i].files[
                  j
                ].url = `${process.env.VUE_APP_API_URL}p/team-chat/redirFile/${data.messagesID}/${curentMessage.files[j].name}.${curentMessage.files[j].extention}?messageIndex=${i}&fileIndex=${j}&token=${this.$store.state.auth.accessToken}`;
              }
            }
          }
          this.messagesLoaded = data.messagesLoaded;

          if (data.reset) {
            this.messages = this.addMessages(data.messages);
          } else {
            this.messages = [
              ...this.addMessages(data.messages),
              ...this.messages,
            ];
          }
        });
        // Recebe o roomId = InboxOperator do outro usuário e o unreadCount atualizado
        socketTC.on("updateUnreadCount", ({ roomId, unreadCount }) => {
          const index = this.rooms.findIndex((room) => room.roomId == roomId);
          this.notificationSum += unreadCount - this.rooms[index].unreadCount;
          this.rooms[index].unreadCount = unreadCount;
        });
      });

      socketTC.on("disconnect", () => {});
    },
    fetchMessages({ room, options = {} }) {
      // Sempre que fizer o fetch colocar 'messagesLoaded = false' para não deixar a tela de mensagem em um carregamento infinito
      this.messagesLoaded = false;
      socketTC.emit("fetchMessages", {
        roomId: room.roomId, // InboxOperator _id do usuário com quem está conversando
        reset: options.reset, // verifica se é um reset, se sim traz as primeiras 100 mensagens
        messagesLength: this.messages.length,
      });
    },

    addMessages(messages) {
      const optionTimestamp = { hour: "numeric", minute: "numeric" };
      const optionDate = { year: "numeric", month: "long", day: "numeric" };
      const locale = "pt-br";

      const newMessages = [];
      if (messages.length) {
        for (var i = 0; i < messages.length; i++) {
          newMessages.push({
            _id: this.messages.length,
            content: messages[i].content,
            senderId: messages[i].senderId,
            files: messages[i].files,
            // username: 'Rodrigo',
            date: new Date(messages[i].timestamp).toLocaleDateString(
              locale,
              optionDate
            ),
            timestamp: new Date(messages[i].timestamp).toLocaleTimeString(
              locale,
              optionTimestamp
            ),
          });
        }
      }

      return newMessages;
    },

    sendMessage(message) {
      // Adiciona no body de mensagens
      message._id = this.messages.length;
      message.senderId = this.currentUserId;
      message.timestamp = new Date().getTime();
      this.messages = [...this.messages, ...this.addMessages([message])];
      // distinguir entre chatGPT e operator
      if (message.roomId !== "000000209d705b9e6ee9e514") {
        window.analytics.track(
          "Enviar mensagem Teamchat",
          {},
          { groupId: this.$store.state.acc.current_acc.id }
        );
        // Envia para o servidor
        socketTC.emit("sendMessage", {
          _id: this.messages.length,
          content: message.content.slice(0, 2047),
          senderId: this.currentUserId,
          roomId: message.roomId,
          files: message.files,
          timestamp: new Date().getTime(),
        });
      } else {
        window.analytics.track(
          "Enviar mensagem Teamchat IA",
          {},
          { groupId: this.$store.state.acc.current_acc.id }
        );
        // Envia para o servidor
        const roomIdx = this.rooms.findIndex(
          (e) => e.roomId === this.currentRoom
        );
        if (!this.rooms[roomIdx].typingUsers)
          this.rooms[roomIdx].typingUsers = [];
        this.rooms[roomIdx].typingUsers = [
          ...this.rooms[roomIdx].typingUsers,
          message.roomId,
        ];

        socketTC.emit(
          "sendMessageGPT",
          {
            _id: this.messages.length,
            content: message.content.slice(0, 2047),
            senderId: this.currentUserId,
            roomId: message.roomId,
            files: message.files,
            timestamp: new Date().getTime(),
          },
          (response) => {
            this.rooms[roomIdx].typingUsers = [];
            this.messages = [...this.messages, ...this.addMessages([response])];
          }
        );
      }
    },
    openFile({ file }) {
      if (file.action == "download") {
        if (file.file.localUrl) {
          window.open(file.file.localUrl);
        } else {
          window.open(`${file.file.url}`);
        }
      }
    },
    // Disparado quando um usuário voltar para a listagem de usuários
    toggleRoomsList({ opened }) {
      if (opened) {
        this.messages = [];
        this.currentRoom = null;
      }
    },
  },
};
</script>

<style lang="scss">
#teamChatFrame {
  border: 0;
  background-color: transparent;
  z-index: 2147483644;
  position: fixed;
  bottom: 0;
  // overflow: hidden;
  margin: 0px 20px 20px;
  opacity: 1;
  right: 0;
  display: flex;
}

#teamChat {
  transition: all 0.3s ease-in-out;

  @media only screen and (max-device-width: 640px) {
    height: 100vh;
  }
}

.chatOpen {
  width: 400px;
  margin: 0 !important;

  @media only screen and (max-device-width: 640px) {
    width: 100vw;
    height: 100vh;
    margin: 0 !important;
  }
}

.vac-card-window {
  box-shadow: none !important;
}

.notification-badge {
  position: absolute;
  top: -5px;
  right: -10px;
  padding: 3px;
  border-radius: 50%;
  background-color: red;
  color: white;
  font-size: 10px;
  font-weight: 500;
  text-align: center;
  min-width: 20px;
}

.custom-tooltip {
  display: block !important;
  height: auto;
  left: 0;
  top: 0;
  z-index: 40000;
  background: #323232;
  padding: 5px 7px;
  font-size: 12px;
  border-radius: 6px;
  max-width: 220px;
  color: #fff;
  margin: 10px 0px;
}

.custom-tooltip:after {
  content: "";
  background: inherit;
  width: 8px;
  height: 8px;
  position: absolute;
  display: block;
  transform: rotate(45deg) translate(-50%);
  left: 80%;
  bottom: 50%;
}

#team-chat-buttom {
  float: right;
}
</style>
