<template>
  <div>
    <h3 class="mb-5 mt-10">
      {{ $t("Campaigns") }}
    </h3>
    <vx-card
      :title="
        $t('NewCampaign') +
        ' SMS' +
        ' - Créditos disponiveis: ' +
        this.$store.state.acc.current_acc.smsCredit
      "
    >
      <div class="mt-5">
        <form-wizard
          color="rgba(var(--vs-primary), 1)"
          errorColor="rgba(var(--vs-danger), 1)"
          :title="null"
          :subtitle="null"
          stepSize="xs"
        >
          <tab-content
            :title="$t('CampaignData')"
            class="mb-5"
            icon="feather icon-edit"
            :before-change="validateStep1"
          >
            <!-- tab 1 content -->
            <form data-vv-scope="step-1">
              <div class="vx-row">
                <div class="vx-col md:w-1/2 w-full mt-5">
                  <div class="mb-5">
                    <p>{{ $t("CampaignName") }}</p>
                    <vs-input
                      v-model="campaignName"
                      class="w-full"
                      name="campaign_name"
                      v-validate="'required'"
                    />
                    <span class="text-danger">{{
                      errors.first("step-1.campaign_name")
                    }}</span>
                  </div>
                  <div class="mb-5">
                    <p>{{ $t("CampaignDescription") }}</p>
                    <vs-input
                      v-model="campaignDescription"
                      class="w-full"
                      name="campaign_description"
                      v-validate="'required'"
                    />
                    <span class="text-danger">{{
                      errors.first("step-1.campaign_description")
                    }}</span>
                  </div>
                  <div class="mb-5">
                    Chatbot
                    <v-select
                      :clearable="false"
                      v-model="currentChat"
                      name="campaign_chatbot"
                      :options="chatOptions"
                      :reduce="(item) => item.value"
                      label="label"
                    >
                    </v-select>
                    <span class="text-danger">{{
                      errors.first("step-1.campaign_chatbot")
                    }}</span>
                  </div>
                </div>
                <div class="vx-col md:w-1/2 w-full mt-5">
                  {{ $t("SMSContentLabel") }}
                  <vs-textarea
                    height="113px"
                    v-model="campaignSMSContent"
                    class="w-full"
                    @input="controlNumberOfCaracters"
                  />
                  <p class="text-right">
                    {{ campaignSMSContent.length }} /
                    {{ campaignSMSContentMaxLength }} caracteres
                  </p>
                  <div class="alert-box flex gap-x-5 mt-4">
                    <info-icon size="2x" class="" color="#7367f0"> </info-icon>
                    <p>
                      * Digite {NOME_CONTATO} para inserir o nome do contato no
                      corpo da mensagem. <br />
                      <strong>Variáveis custom não funcionam</strong> nestas
                      campanhas
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </tab-content>
          <!-- tab 2 content -->
          <tab-content
            :title="$t('ContactsData')"
            class="mb-5"
            icon="feather icon-briefcase"
            :before-change="validateStep2"
          >
            <form data-vv-scope="step-2">
              <div class="vx-row">
                <div class="vx-col flex mb-5 space-between">
                  <div class="self-center mr-5">
                    <input
                      type="file"
                      id="imgupload"
                      accept=".csv"
                      style="display: none"
                      @change="onFileChange"
                    />
                    <vs-button
                      color="primary"
                      class="self-center"
                      size="big"
                      @click="showUploadDialog"
                    >
                      {{ $t("CampaignUploadCSV") }}
                    </vs-button>
                    <p v-if="contactsFileName" class="text-center">
                      <small>{{ contactsFileName }}</small>
                    </p>
                  </div>
                  <div class="self-center">
                    Faça o upload de uma planilha com os dados dos contatos para
                    sua campanha. Verifique o padrão de nossa planilha
                    <a
                      href="https://cdn.duotalk.com.br/data/CampaignCSVExample.csv"
                      target="_blank"
                      >com nossa planilha exemplo</a
                    >.
                  </div>
                </div>
                <div class="vx-col w-full">
                  <vs-checkbox
                    v-model="acceptTerms"
                    class="mb-3 vs-checkbox-large"
                    color="success"
                    v-validate="'required'"
                    name="accept_terms"
                  >
                    <p class="ml-6">
                      <small>
                        {{ $t("CampaignCSVTerms") }}
                        {{ $t("AgreeWithPolicy") }}
                        <a
                          href="https://www.duotalk.io/termos-de-uso/"
                          target="_blank"
                          >{{ $t("TermsOfService") }}</a
                        >
                        {{ $t("And") }}
                        <a
                          href="https://www.duotalk.io/politica-de-privacidade/"
                          target="_blank"
                          >{{ $t("PrivacyPolicy") }}</a
                        >.
                      </small>
                    </p>
                  </vs-checkbox>
                  <span class="text-danger" v-if="agreeToTerms">{{
                    $t("CampaignAgreeBeforeContinueCSV")
                  }}</span>
                </div>
              </div>
            </form>
          </tab-content>
          <!-- tab 3 content -->
          <tab-content
            :title="$t('ReviewAndSend')"
            class="mb-5"
            icon="feather icon-play"
            :before-change="validateStep3"
          >
            <form data-vv-scope="step-3">
              <div class="vx-row">
                <div class="vx-col w-full">
                  <ul>
                    <li>{{ $t("CampaignName") }}: {{ campaignName }}</li>
                    <li>
                      {{ $t("CampaignDescription") }}: {{ campaignDescription }}
                    </li>
                    <li>{{ $t("File") }}: {{ contactsFileName }}</li>
                  </ul>
                </div>
              </div>
              <div
                class="alert-box flex gap-x-5 mt-4"
                v-if="
                  contactsList.length >
                  this.$store.state.acc.current_acc.smsCredit
                "
              >
                <info-icon size="2x" class="" color="#7367f0"> </info-icon>
                <p>
                  Créditos insuficientes para o envio completo, serão enviados
                  somente os primeiros
                  {{ this.$store.state.acc.current_acc.smsCredit }} contatos
                  listados.
                </p>
              </div>
              <div class="vx-col w-full mt-5">
                <div class="vx-col w-full mb-5">
                  <div class="mb-3">
                    Resumo dos primeiros contatos da lista enviada
                  </div>
                  <vs-table stripe :data="contactsList" maxHeight="500px">
                    <template slot="thead">
                      <vs-th>#</vs-th>
                      <vs-th>{{ $t("Name") }}</vs-th>
                      <vs-th>{{ $t("Phone") }}</vs-th>
                    </template>
                    <template slot-scope="{ data }">
                      <vs-tr
                        :state="
                          invalidNumbersIndex.indexOf(indextr) > -1
                            ? 'danger'
                            : null
                        "
                        :key="indextr"
                        v-for="(tr, indextr) in data"
                      >
                        <vs-td :data="indextr">
                          {{ indextr + 1 }}
                        </vs-td>
                        <vs-td :data="data[indextr][0]">
                          {{ data[indextr][0] }}
                        </vs-td>
                        <vs-td :data="data[indextr][1]">
                          {{ data[indextr][1] }}
                        </vs-td>
                      </vs-tr>
                    </template>
                  </vs-table>
                </div>
              </div>
            </form>
          </tab-content>
          <vs-button slot="prev" icon-pack="feather" icon="icon-arrow-left">
            {{ $t("Back") }}
          </vs-button>
          <vs-button
            slot="next"
            icon-pack="feather"
            icon="icon-arrow-right"
            icon-after
          >
            {{ $t("Next") }}
          </vs-button>
          <template slot="finish">
            <div class="flex">
              <vs-button
                icon-pack="feather"
                icon="icon-clock"
                icon-after
                type="border"
                class="mr-3"
                @click="popupScheduleCampaign = true"
              >
                Agendar
              </vs-button>
              <vs-button
                icon-pack="feather"
                icon="icon-send"
                icon-after
                @click="popupActive = true"
              >
                {{ $t("Send") }}
              </vs-button>
            </div>
          </template>
        </form-wizard>
      </div>
    </vx-card>
    <vs-popup title="" :active.sync="popupActive" button-close-hidden>
      <div class="flex flex-wrap">
        <div class="w-full text-center">
          <alert-circle-icon size="4x" class="" color="#ff9e41">
          </alert-circle-icon>
          <p class="text-2xl mb-5">Tudo pronto para disparar?</p>
          <p class="leading-5">
            A mensagem da campanha "<span class="font-bold">{{
              campaignName
            }}</span
            >" será<br />disparada assim que você confirmar abaixo.<br />
            Certifique que esteja tudo certo para o envio. <br />
          </p>
        </div>
        <div class="pt-5 w-full flex justify-center gap-x-4">
          <vs-button @click="create()" size="medium"
            >Sim, enviar mensagem</vs-button
          >
          <vs-button
            @click="popupActive = false"
            size="medium"
            color="danger"
            type="border"
            >Cancelar</vs-button
          >
        </div>
      </div>
    </vs-popup>
    <vs-popup title="Agendar campanha" :active.sync="popupScheduleCampaign">
      <vs-row>
        <vs-col vs-type="flex" vs-justify="center">
          <div style="width: 210px">
            <div
              class="vs-component vs-con-input-label vs-input vs-input-primary"
            >
              <label class="vs-input--label">Data</label>
              <date-picker
                v-model="scheduleDate"
                format="DD-MM-YYYY"
                :disabled-date="disabledDate"
                popup-class="z-9999999"
              />
            </div>
            <div
              class="vs-component vs-con-input-label vs-input vs-input-primary mt-5"
            >
              <label class="vs-input--label">Hora</label>
              <date-picker
                type="time"
                v-model="scheduleTime"
                :disabled-date="disabledDate"
                format="HH:00"
                :show-second="false"
                popup-class="z-9999999"
                :time-picker-options="hoursAvailable"
              />
            </div>
            <vs-button
              icon-pack="feather"
              icon="icon-clock"
              icon-after
              class="mt-5 float-right"
              :disabled="scheduleDate === '' || scheduleTime === ''"
              @click="
                popupScheduleCampaign = false;
                confirmSchedule = true;
                create();
              "
            >
              Agendar
            </vs-button>
          </div>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>
<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { AlertCircleIcon, InfoIcon } from "vue-feather-icons";
import vSelect from "vue-select";
//date picker
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pt-br";
export default {
  data() {
    return {
      scheduleDate: "",
      scheduleTime: "",
      confirmSchedule: false,
      popupScheduleCampaign: false,
      popupActive: false,
      sendToOpenConversations: 2,
      campaignIntention: null,
      campaignEntity: null,
      entitiesOptions: [],
      intentionsOptions: [],
      contactListFieldEditValue: "",
      checkTheNumbers: false,
      invalidNumbersIndex: [],
      contactsList: [],
      csvHeader: [],
      totalValidContacts: 0,
      agreeToTerms: null,
      contactsFileObject: null,
      contactsFileBase64: null,
      currentChat: null,
      acceptTerms: false,
      campaignTemplate: null,
      campaignName: "",
      campaignDescription: "",
      templatesOptions: [],
      wppProvider: "smarters",
      creating: false,
      delimiter: "",
      campaignSMSContent: "",
      campaignSMSContentMaxLength: 160,
    };
  },
  watch: {
    popupScheduleCampaign() {
      if (!this.popupScheduleCampaign && !this.confirmSchedule) {
        this.scheduleDate = "";
        this.scheduleTime = "";
      }
    },
  },
  computed: {
    contactsFileName() {
      if (this.contactsFileObject) return this.contactsFileObject.name;
      return "";
    },
    templateName() {
      const template = this.templatesOptions.find(
        (el) => el._id === this.campaignTemplate
      );
      if (template) return template.name || template.title;
      return "";
    },
    templateWppID() {
      const template = this.templatesOptions.find(
        (el) => el._id === this.campaignTemplate
      );
      if (template)
        return template.id || template.whatsappApiTemplates[0].templateID;
      return null;
    },
    chatOptions() {
      return this.$store.state.chat.chats;
    },
    disabledDate() {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return (date) => {
        const inputDate = new Date(date);
        inputDate.setHours(0, 0, 0, 0);
        return inputDate < today;
      };
    },
    hoursAvailable() {
      const now = new Date();
      let start = "07:00";
      if (new Date(this.scheduleDate).getDate() === now.getDate()) {
        start = `${now.getHours() + 1}:00`;
      }
      return { start, step: "01:00", end: "18:00", format: "HH:mm" };
    },
  },
  methods: {
    controlNumberOfCaracters() {
      this.campaignSMSContent = this.campaignSMSContent.slice(
        0,
        this.campaignSMSContentMaxLength
      );
    },
    guessDelimiters(text) {
      const possibleDelimiters = [";", ",", "\n", "|"];
      return possibleDelimiters.filter(weedOut);
      function weedOut(delimiter) {
        var cache = -1;
        return text.split("\n").every(checkLength);
        function checkLength(line) {
          if (!line) {
            return true;
          }
          var length = line.split(delimiter).length;
          if (cache < 0) {
            cache = length;
          }
          return cache === length && length > 1;
        }
      }
    },
    getBase64() {
      const thisIns = this;
      const reader = new FileReader();
      reader.readAsDataURL(this.contactsFileObject);
      reader.onload = function () {
        thisIns.contactsFileBase64 = reader.result;
        const csvData = Buffer.from(
          reader.result.replace("data:text/csv;base64,", ""),
          "base64"
        )
          .toString()
          .split("\n");
        const delimiter = thisIns.guessDelimiters(csvData[0]);
        if (delimiter.length > 0) {
          thisIns.delimiter = delimiter[0];
          let j = 0;
          for (let i = 0; i < 11; i += 1) {
            if (!csvData || !csvData[i] || typeof csvData[i] !== "string")
              continue;
            const rowEl = csvData[i]
              .replace("\r", "")
              .replace("\n", "")
              .replace("\t", "")
              .replace(/['']+/g, "")
              .replace(/['"]+/g, "")
              .split(delimiter[0]);
            if (i > 0) {
              if (
                rowEl[0] &&
                rowEl[1] &&
                rowEl[1].length > 0 &&
                rowEl[0].length > 0
              ) {
                j += 1;
                if (
                  rowEl[1] &&
                  !thisIns.checkTheNumbers &&
                  /[a-zA-Z]/g.test(rowEl[1])
                )
                  thisIns.checkTheNumbers = true;
                if (rowEl[1] && /[a-zA-Z]/g.test(rowEl[1]))
                  thisIns.invalidNumbersIndex.push(i - 1);
                if (rowEl[2])
                  rowEl[2] = String(
                    rowEl[2]
                      .replace("\r", "")
                      .replace("\n", "")
                      .replace("\t", "")
                      .replace('"', "")
                      .replace("'", "")
                  );
                thisIns.contactsList.push(rowEl);
              }
            } else thisIns.csvHeader = rowEl;
          }
          thisIns.totalValidContacts = j;
        }
      };
      reader.onerror = function (error) {
        thisIns.$vs.notify({
          time: 2500,
          title: thisIns.$t("Error"),
          text: `${thisIns.$t("UnexpectedError")} - ${error}`,
          iconPack: "feather",
          icon: "icon-success-circle",
          color: "danger",
          position: "top-right",
        });
      };
    },
    async create() {
      if (this.creating) return;
      this.creating = true;
      let schedule = false;
      if (this.scheduleDate !== "" && this.scheduleTime !== "") {
        schedule = true;
        this.scheduleDate.setHours(this.scheduleTime.getHours());
      } else {
        this.scheduleDate = null;
      }
      let fd = new FormData();
      fd.append("content", this.contactsFileObject);
      fd.append("filename", this.contactsFileName);
      fd.append("name", this.campaignName);
      fd.append("type", "sms");
      fd.append("description", this.campaignDescription);
      fd.append("intention", this.campaignIntention);
      fd.append("entity", this.campaignEntity);
      fd.append("sms[content]", this.campaignSMSContent);
      fd.append("csvHeader", JSON.stringify(this.csvHeader));
      fd.append("acc", this.$store.state.acc.current_acc.id);
      fd.append("chat", this.currentChat);
      // fd.append("delimiter", this.delimiter);
      fd.append("scheduled", schedule);
      fd.append("scheduleDate", this.scheduleDate);
      await this.$http
        .post(`/p/campaign/create`, fd, {
          headers: {
            Authorization: "Bearer " + this.$store.state.auth.accessToken,
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          window.analytics.track(
            "Campanha Enviada",
            {},
            { groupId: this.$store.state.acc.current_acc.id }
          );
          this.$vs.notify({
            time: 2500,
            title: this.$t("Success"),
            text: "Campanha criada com sucesso.",
            iconPack: "feather",
            icon: "icon-check",
            color: "success",
            position: "top-right",
          });
          this.$router.push("/apps/campaigns");
        })
        .catch((error) => {
          if (typeof error.request !== "undefined")
            if (typeof error.request.response !== "undefined")
              error.message = JSON.parse(error.request.response).message;
          this.creating = false;
          this.$vs.notify({
            time: 6000,
            title: this.$t("Error"),
            text: `${this.$t("CSVStructureError")} ${error.message}`,
            iconPack: "feather",
            icon: "icon-success-circle",
            color: "danger",
            position: "top-right",
          });
        });
    },
    onFileChange(e) {
      this.totalValidContacts = 0;
      this.contactsList = [];
      this.checkTheNumbers = false;
      this.invalidNumbersIndex = [];
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.contactsFileObject = files[0];
      this.getBase64();
    },
    async getChats() {
      await this.$http
        .get("/p/chat/list", {
          params: {
            acc: this.$store.state.acc.current_acc.id,
          },
        })
        .then(async (response) => {
          for (var i = 0; i < response.data.data.length; i++) {
            this.$store.dispatch("chat/setChatsItem", {
              label: response.data.data[i].name,
              value: response.data.data[i]._id,
            });
          }
        })
        .catch((error) => {
          if (typeof error.request !== "undefined")
            if (typeof error.request.response !== "undefined")
              error.message = JSON.parse(error.request.response).message;
            else error.message = this.$t("UnexpectedErrorLoadX", ["chatbots"]);
          this.$vs.notify({
            title: this.$t("UnexpectedError"),
            text: this.$t("UnexpectedError"),
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "warning",
            position: "top-right",
            time: 4000,
          });
        });
    },
    validateStep1() {
      return new Promise((resolve, reject) => {
        this.$validator.validateAll("step-1").then((result) => {
          if (result) {
            resolve(true);
          } else {
            reject("correct all values");
          }
        });
      });
    },
    validateStep2() {
      return new Promise((resolve, reject) => {
        this.agreeToTerms = false;
        if (!this.acceptTerms) {
          reject("correct all values");
          this.agreeToTerms = true;
        }
        this.$validator.validateAll("step-2").then((result) => {
          if (result) {
            resolve(true);
          } else {
            reject("correct all values");
          }
        });
      });
    },
    validateStep3() {
      return new Promise((resolve, reject) => {
        if (this.checkTheNumbers) reject("");
        else {
          this.$validator.validateAll("step-3").then((result) => {
            if (result) {
              resolve(true);
            } else {
              reject("correct all values");
            }
          });
        }
      });
    },
    showUploadDialog() {
      const uploadElm = document.querySelector("#imgupload");
      if (uploadElm) uploadElm.click();
    },
  },
  mounted() {
    if (
      !this.$store.state.chat.chats ||
      this.$store.state.chat.chats.length === 0
    )
      this.getChats();
    this.$http
      .get(`/p/chat/inbox/${this.$store.state.acc.current_acc.id}`, {
        params: {
          acc: this.$store.state.acc.current_acc.id,
        },
      })
      .then((res) => {
        window.analytics.track(
          "Campanha Iniciada",
          {},
          { groupId: this.$store.state.acc.current_acc.id }
        );
        const entities = res.data.data.inbox.keyEntities;
        this.entitiesOptions = ["", ...entities];
        const intentions = res.data.data.inbox.intentions;
        this.intentionsOptions = [...new Set(intentions)];
      });
  },
  components: {
    FormWizard,
    TabContent,
    AlertCircleIcon,
    InfoIcon,
    vSelect,
    DatePicker,
  },
};
</script>
<style lang="scss">
.vs-popup--header {
  background: none !important;
}
.alert-box {
  padding: 1rem;
  background-color: rgba(115, 103, 240, 0.2);
  border-radius: 6px;
}
</style>
