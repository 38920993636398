<template>
  <vs-card class="w-full">
    <div slot="header" class="flex items-center justify-between mx-4 mt-4">
      <h4>{{ cardTitle }}</h4>
    </div>
    <vs-row>
      <vs-col vs-w="12">
        <vs-table
          hoverFlat    
          max-items="10"
          pagination
          class="userTable"
          :data="cardData">
          <template slot="thead">
            <vs-th class="w-1/5">{{ $t("Name") }}</vs-th>
            <vs-th class="w-1/5" sort-key="campaign">{{ $t("Campaign") }}</vs-th>
            <vs-th class="w-1/5" sort-key="waitingAttendance">{{ $t("WaitingAttendance") }}</vs-th>
            <vs-th class="w-1/5" sort-key="inAttendance">{{ $t("InAttendance") }}</vs-th>
            <vs-th class="w-1/5" sort-key="totalCount">Total</vs-th>
          </template>
          <template slot="default" slot-scope="{ data }">
            <vs-tr v-for="(item, index) in data" :key="index">
              <vs-td>{{ item.operatorUserName ? item.operatorUserName : formatId(item._id) }}</vs-td>
              <vs-td>{{ item.campaign }}</vs-td>
              <vs-td>{{ item.waitingAttendance }}</vs-td>
              <vs-td>{{ item.inAttendance }}</vs-td>
              <vs-td>{{ item.totalCount }}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vs-col>
    </vs-row>
  </vs-card>
</template>

<script>
export default {
  name: "CardConversationsTable",
  props: {
    cardTitle: String,
    cardData: Array,
  },
  methods: {
    formatId(id) {
      if (!id || id.length === 0) {
        return "-";
      }
      return Array.isArray(id) ? id[0] : id;
    }
  },
}
</script>

<style scoped>
.userTable >>> .vs-table--tbody .vs-table--td:nth-child(1),
.userTable >>> .vs-table--thead th:nth-child(1) {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.userTable >>> .vs-table--thead th {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.userTable >>> .vs-table--tbody .vs-table--tr:last-child .vs-table--td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.userTable >>> .vs-table--tbody .vs-table--tr .vs-table--td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
</style>