<template>
  <div class="flex w-full">
    <div class="w-full md:w-7/12 p-3 md:p-6">
      <h1 class="text-center mb-6 flex" :class="{ 'justify-center': !newFlow }">
        <vs-button
          v-if="newFlow"
          @click="voltar()"
          class="border-none p-2 mr-8"
          type="border"
          color="primary"
          icon-pack="feather"
          icon="icon-arrow-left"
        >
          {{ $t("GoBack") }}
        </vs-button>
        {{ $t("EditFlow") }}
      </h1>

      <!-- Editar ou criar Fluxo -->
      <div>
        <div class="flex flex-wrap mb-3">
          <chatbot-questions
            class="mt-4"
            :formFields="formFields"
            :indexArrayPosition="0"
            :isCustom="isCustom"
            @save-flow="saveFlow"
          ></chatbot-questions>
        </div>
      </div>
    </div>

    <!-- Chatbot preview -->
    <div class="hidden sm:flex sm:w-5/12 mr-4">
      <div class="w-full mb-2 p-4 ml-6 pt-0 flex justify-center">
        <div class="chatbot-example-frame text-center">
          <div class="flex justify-center w-full">
            <vs-button
              icon-pack="feather"
              icon="icon-rotate-ccw"
              class="mt-6 mb-8 w-full"
              type="border"
              color="grey"
              @click="showChatbot()"
              ><h5 id="chatbot-ex">{{ $t("TestChatbot") }}</h5></vs-button
            >
          </div>
          <div id="chatbot-example"></div>
        </div>
      </div>
    </div>
    <vs-button
      class="sm:hidden preview-btn-onboarding transform rotate-90"
      @click="showChatPopup()"
      color="primary"
      type="gradient"
      icon="icon-eye"
      icon-pack="feather"
      >{{ $t("PreviewChatbot") }}
    </vs-button>
    <vs-popup
      :title="$t('ChatbotPreview')"
      :active.sync="previewChatbot"
      id="preview-chatbot"
    >
      <div class="vx-col w-full" v-if="previewChatbot">
        <div
          id="chatbot-mobile-example"
          class="top-0 vx-col w-full chatbot-example"
        ></div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import ChatbotQuestions from "./ChatbotQuestions.vue";
import { deepChangeId } from "@/helpers/flowEdit.js";

export default {
  components: {
    ChatbotQuestions,
  },
  computed: {
    templateId() {
      return this.$store.state.chatbotManagement.current_chatbot.template;
    },
    planChatType() {
      return this.$store.state.acc.current_acc.accPlan.plan.planParameters
        .chatType;
    },
  },
  watch: {
    chatbotSelected: async function (val, old) {
      if (this.chatbotSelectedAlreadyLoaded) {
        if (this.isCustom) {
          if (val != old.value && this.isClicked) {
            this.acceptTemplate = val;
            this.accept = false;
            this.$vs.dialog({
              type: "confirm",
              color: "danger",
              title: "Mudar template",
              text: "Ao alterar o template, toda customização de fluxo será perdida. Deseja alterar o template?",
              accept: this.acceptChange,
              acceptText: "Alterar",
              cancelText: this.$t("Cancel"),
            });
          }
          if (!this.accept && this.isClicked) {
            this.chatbotSelected = old;
            this.isClicked = false;
          }
          if (this.accept) {
            this.isClicked = false;
            this.chatbotSelected = val;
          }
        } else {
          await this.fetchTemplates(this.chatbotSelected);
          this.saveFlow([], true);
        }
      } else {
        this.chatbotSelectedAlreadyLoaded = true;
      }
    },
    responseType: function () {
      if (this.responseType.value == "suggestion") this.listenerSuggestion();
    },
  },
  data() {
    return {
      nameIntention: "",
      delete: {},
      duplicated: {},
      formFields: [],
      edit: false,
      firstMessage: "",
      newFlow: false,
      questions: [],
      //nome do fluxo
      intention: "",
      question: "",
      suggestions: "",
      suggestionsArray: [],
      previewChatbot: false,
      isClicked: false,
      templateOptions: [],
      chatbotSelected: "",
      chatbotSelectedAlreadyLoaded: false,
      companyIndustry: "",
      isCustom: false,
      tagsItems: [],
      acceptTemplate: {},
      accept: false,
    };
  },
  beforeMount() {
    if (this.templateId) this.chatbotSelected = this.templateId;
  },
  created() {
    this.getCompanyIndustry();
    this.loadChatWidget();
    this.templateData();
    this.getTemplates();
  },
  mounted() {},
  methods: {
    showChatbot() {
      let self = this;
      if (!this.widgetLoaded)
        setTimeout(function () {
          self.loadChatWidget();
        }, 2000);
      else this.postFormFiels();
    },
    async acceptChange() {
      this.isCustom = false;
      this.accept = true;
      this.chatbotSelected = this.acceptTemplate;
      await this.fetchTemplates(this.chatbotSelected);
    },
    getCompanyIndustry() {
      let id = this.$store.state.acc.current_acc.id;
      this.$http.get("/g/acc/" + id).then((response) => {
        this.companyIndustry = response.data.data.acc.companyIndustry;
      });
    },
    postFormFiels() {
      var iframe = document.getElementById(
        "cb-duotalk-chat-framechatbot-example"
      );
      if (iframe) {
        var message = {};
        message.botMessages = this.formFields;
        if (iframe.contentWindow)
          iframe.contentWindow.postMessage(message, "*");
      }
    },
    voltar() {
      this.newFlow = false;
      this.flow = [];
      this.edit = false;
      this.intention = "";
      this.nameIntention = "";
      this.question = "";
      this.firstMessage = "";
    },
    confirmDeleteFlow(index) {
      this.delete = {};
      this.delete = { index: index };
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: "Deletar fluxo",
        text: "Deseja deletar o fluxo? Isto irá excluir permanentemente o fluxo.",
        accept: this.deleteFlow,
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel"),
      });
    },
    saveFlow(flow, custom) {
      flow = deepChangeId(flow);
      if (this.isCustom || (!this.isCustom && custom)) {
        this.$http({
          method: "put",
          url: `/p/chat/chatbot-edit/${this.$store.state.chatbotManagement.current_chatbot.id}`,
          data: {
            formFields: flow,
            template: this.chatbotSelected,
            acc: this.$store.state.acc.current_acc.id,
          },
        })
          .then(() => {
            this.$vs.notify({
              title: this.$t("Success"),
              text: this.$t("ChatbotEditedSuccessfuly"),
              iconPack: "feather",
              icon: "icon-success-circle",
              color: "success",
              position: "top-right",
              time: 4000,
            });
            const createCustomName = (label) => {
              if (label.includes("- Custom")) return label;
              else return `${this.chatbotSelected.label} - Custom`;
            };

            if (typeof flow[0] === "object") {
              this.formFields = flow;
              this.isCustom = true;
              this.chatbotSelected = {
                ...this.chatbotSelected,
                label: createCustomName(this.chatbotSelected.label),
              };
            }
          })
          .catch(() => {});
      }
    },
    getTemplates() {
      this.$http
        .get("/p/chat/template/list", {
          params: {
            acc: this.$store.state.acc.current_acc.id,
          },
        })
        .then((response) => {
          let templateOptionsFull = [];
          let push = false;
          for (let i = 0; i < response.data.data.length; i++) {
            push = false;
            if (this.chatbot === response.data.data[i]._id) push = true;
            else if (response.data.data[i].listingAvailable === true)
              push = true;

            if (push)
              templateOptionsFull.push({
                value: response.data.data[i]._id,
                label: response.data.data[i].name,
                chatType: response.data.data[i].chatType,
                botMessages: response.data.data[i].formFields,
              });

            if (this.chatbot === response.data.data[i]._id) {
              this.chatbotSelected = response.data.data[i]._id;
            }
          }

          if (this.templateOptions.length < 1) {
            this.templateOptions = templateOptionsFull;
            // Removing autoForce template from the start
            let autoForceTempIndex = this.templateOptions.findIndex((obj) =>
              obj.label.includes("AutoForce")
            );
            if (autoForceTempIndex !== -1) {
              let [obj] = this.templateOptions.splice(autoForceTempIndex, 1);
              this.templateOptions.push(obj);
            }
          }
          if (this.chatbotSelected.length < 1) {
            this.chatbotSelected = this.templateOptions[0].value;
          }
        })
        .catch(() => {
          this.$vs.notify({
            title: this.$t("UnexpectedError"),
            text: this.$t("UnexpectedErrorLoadX", [
              this.$t("ChatbotExample").toLowerCase(),
            ]),
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "warning",
            duration: 10000,
            position: "top-right",
            time: 4000,
          });
        });
    },
    async fetchTemplates(id) {
      this.isCustom = false;
      this.$http.get("p/chat/template/" + id).then((response) => {
        this.formFields = response.data.data.formFields;
        return response.data.data.formFields;
      });
    },
    templateData() {
      let formFields = JSON.parse(
        JSON.stringify(
          this.$store.state.chatbotManagement.current_chatbot.formFields
        )
      );
      if (typeof formFields[0] === "object") {
        this.formFields = formFields;
        this.isCustom = true;
      } else {
        this.fetchTemplates(this.templateId);
      }
    },
    removeSuggestion(index) {
      this.suggestionsArray.splice(index, 1);
    },
    listenerSuggestion() {
      let self = this;
      const suggestions = document.getElementById("suggestions");
      if (suggestions) {
        suggestions.addEventListener("blur", function () {
          if (self.suggestions.length > 3) {
            self.suggestionsArray.push(self.suggestions);
            self.suggestions = "";
          }
        });
        suggestions.addEventListener("keyup", function onEvent(e) {
          if (e.keyCode === 13) {
            if (self.suggestions.length > 3) {
              self.suggestionsArray.push(self.suggestions);
              self.suggestions = "";
            }
          }
        });
      }
    },
    loadChatWidget() {
      this.widgetLoaded = true;
      var self = this;
      var obj = document.getElementById("chatbot-example");
      if (obj != null) obj.innerHTML = "";

      window.__cb = window.__be || {};
      window.__cb.bind = "chatbot-example";
      window.__cb.chatOpen = true;
      window.__cb.closeButton = false;
      window.__cb.template = this.templateId;
      (function () {
        var be = document.createElement("script");
        be.type = "text/javascript";
        be.async = true;
        be.src = process.env.VUE_APP_WIDGET_PLUGIN_URL;
        var s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(be, s);
      })();
      setTimeout(function () {
        self.postFormFiels();
      }, 5000);
      if (this.previewChatbot == true)
        setTimeout(function () {
          self.setChatbotMobileExample();
        }, 2000);
    },
    showChatPopup() {
      this.previewChatbot = true;
      this.loadChatWidget();
    },
    setChatbotMobileExample() {
      var obj = document.getElementById("chatbot-example");
      var mobileObj = document.getElementById("chatbot-mobile-example");
      mobileObj.innerHTML = obj.innerHTML;
    },
  },
};
</script>

<style lang="scss">
.label-onboarding {
  font-size: 0.85rem;
  background-color: white;
  margin-left: 1rem;
  padding: 0 0.5rem 0 0.5rem;
  width: fit-content;
  position: relative;
  bottom: -10px;
  order: -1;
}
.input-onboarding {
  padding-left: 8px;
}
.input-onboarding:focus {
  border: solid 1px rgba(var(--vs-primary), 1);
}
.input-onboarding:focus + span + .label-onboarding,
.input-onboarding:focus + .label-onboarding,
.vue-tel-input:focus-within + span + .label-onboarding {
  color: rgba(var(--vs-primary), 1);
}
</style>
