<template>
  <div>
    <vs-row>
      <vs-col vs-w="12">
        <label class="vs-input--label mb-2">{{ $t("Trigger") }}</label>
        <vs-select width="100%" v-model="createAutomation.trigger" disabled>
          <vs-select-item v-for="(trigger, index) in triggers" :value="trigger.value" :text="trigger.label"
            :key="index" />
        </vs-select>
      </vs-col>
    </vs-row>
    <vs-row>
      <vs-col vs-w="12">
        <vs-input name="title" v-model="createAutomation.title" type="text" class="w-full mt-4" :label="$t('Title')"
          :danger="validationErrors.title" :danger-text="$t('Required')" />
      </vs-col>
    </vs-row>
    <vs-col vs-w="12" class="mt-4">
      <div class="vs-component vs-con-input-label vs-input w-full vs-input-primary">
        <label class="vs-input--label mb-2">{{ $t("Chatbots") }}</label>
        <vs-select :danger="validationErrors.chat" :danger-text="$t('Required')" class="w-full" v-model="chat"
          autocomplete placeholder="Selecione">
          <vs-select-item v-for="(chat, chatIndex) in chatbotData" :value="chat" :text="chat.name" :key="chatIndex" />
        </vs-select>
      </div>
    </vs-col>
    <vs-col vs-w="12" class="mt-4" v-if="Object.keys(chat).length">
      <div class="vs-component vs-con-input-label vs-input w-full vs-input-primary">
        <label class="vs-input--label mb-2">{{ $t("Intention") }}</label>
        <vs-select class="w-full" v-model="createAutomation.automationSetup.intentions"
          :danger="validationErrors.intentions" :danger-text="$t('Required')" multiple>
          <vs-select-item v-for="(intention, index) in chat.intentions" :value="intention" :text="intention"
            :key="index" />
        </vs-select>
      </div>
    </vs-col>
    <vs-col vs-w="12" class="mt-4" v-if="Object.keys(chat).length && chat.keyEntities.length">
      <div class="vs-component vs-con-input-label vs-input w-full vs-input-primary">
        <label class="vs-input--label">{{ $t("KeyEntities") }}</label>
        <vs-select class="w-full" v-model="createAutomation.automationSetup.entities" multiple>
          <vs-select-item v-for="(entity, index) in chat.keyEntities" :value="entity" :text="entity" :key="index" />
        </vs-select>
      </div>
    </vs-col>
    <vs-col vs-w="12" class="mt-4" v-if="Object.keys(chat).length">
      <div class="vs-component vs-con-input-label vs-input w-full vs-input-primary">
        <label class="vs-input--label">{{ $t("ConversationStage") }}</label>
        <vs-select class="w-full" v-model="createAutomation.automationSetup.stages" :danger="validationErrors.stages"
          :danger-text="$t('Required')" multiple>
          <vs-select-item v-for="(stage, index) in stages" :value="stage" :text="stage.label" :key="index" />
        </vs-select>
      </div>
    </vs-col>
    <vs-col vs-w="12" class="mt-4" v-if="Object.keys(chat).length">
      <label class="vs-input--label mb-2">{{ $t("Action") }}</label>
      <vs-select width="100%" v-model="createAutomation.automationSetup.action">
        <vs-select-item v-for="(action, index) in actions" :value="action.value" :text="action.label" :key="index" />
      </vs-select>
    </vs-col>
    <vs-row>
      <vs-col vs-w="12" class="mt-4" v-if="Object.keys(chat).length">
        <label class="vs-input--label">{{ $t("When") }}?</label>
        <div class="flex items-center">
          <label class="mr-4 mt-4 vs-input--label">{{ $t("SendAfter") }}</label>
          <div>
            <!-- <vs-input-number :label="$t('Day')" v-model="createAutomation.automationSetup.timeDay" :min="0" :max="7" /> -->
            <vs-row class="flex space-x-4">
              <vs-col class="flex-1">
                <vs-input type="number" min="1" placeholder="1" v-model="createAutomation.automationSetup.timeMinute"
                  :label="$t('Minutes')" />
              </vs-col>
              <vs-col class="flex-1">
                <vs-input type="number" min="0" v-model="createAutomation.automationSetup.timeHour"
                  :label="$t('Hours').charAt(0).toUpperCase() + $t('Hours').slice(1)" />
              </vs-col>
            </vs-row>
          </div>
        </div>
      </vs-col>
    </vs-row>
    <vs-col vs-w="12" class="mt-4" v-if="createAutomation.automationSetup.action === 'sendTemplate'">
      <div class="vs-component vs-con-input-label vs-input w-full vs-input-primary">
        <label class="vs-input--label">{{ $t("Templates") }}</label>
        <vs-select v-model="createAutomation.automationSetup.templateID" class="w-full"
          :danger="validationErrors.template" :danger-text="$t('Required')">
          <vs-select-item v-for="(template, index) in templateData" :value="template.id" :text="template.title"
            :key="index" />
        </vs-select>
      </div>
      <!-- <div class="mt-1 flex items-center" @click="open360Template()">
       <vs-icon icon-pack="feather" icon="icon-external-link" color="primary" />
        <span class="ml-1 text-primary">Visualizar templates</span>      
      </div> -->
    </vs-col>
    <vs-col vs-w="12" class="mt-4 w-full" v-if="createAutomation.automationSetup.action === 'sendTemplate'">
      <div class="text-primary background-color px-2 py-3 rounded-lg w-full">{{ $t("TemplateSending") }}</div>
    </vs-col>
    <vs-col vs-w="12" class="mt-4"
      v-if="Object.keys(chat).length && createAutomation.automationSetup.action !== 'sendTemplate'">
      <vs-textarea name="message" class="w-full mt-3 mb-0" v-model="createAutomation.automationSetup.message"
        style="cursor: not-allowed" rows="5" :placeholder="$t('WriteAMessage')" />
      <span v-if="validationErrors.message" class="danger mt-1">{{ $t('Required') }}</span>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="flex-end" class="mt-6 mb-6 w-full">
      <vs-button class="w-full" icon-pack="feather" icon="icon-save" size="medium" @click=createNewAutomation()
        color="#28c66f">
        {{ $t("Save") }}
      </vs-button>
    </vs-col>
  </div>
</template>

<script>
import axios from "@/axios";

export default {
  name: "createAutomationPopup",
  data() {
    return {
      triggers: [
        { label: this.$t("Inactivity"), value: "inactivity" }
      ],
      actions: [
        { label: this.$t("SendMessage"), value: "sendMessage" },
        { label: this.$t("SendTemplate"), value: "sendTemplate" }
      ],
      stages: [
        { label: this.$t("Campaign"), value: 1 },
        { label: this.$t("WaitingAttendance"), value: 2 },
        { label: this.$t("InAttendance"), value: 3 },
      ],
      chat: {},
      createAutomation: {
        acc: this.$store.state.acc.current_acc.id,
        user: this.$store.state.user._id,
        title: null,
        chat: null,
        trigger: "inactivity",
        automationSetup: {
          timeCalculated: 0,
          timeDay: 0,
          timeHour: 0,
          timeMinute: 1,
          entities: [],
          intentions: [],
          stages: [],
          action: "sendMessage",
          message: null,
          templateID: null,
        },
      },
      chatbotData: null,
      templateData: [],
      validationErrors: {},
    };
  },
  watch: {
    chat() {
      this.createAutomation.automationSetup.intentions = [];
      this.createAutomation.automationSetup.entities = [];
      const all = this.$t("All");
      if (Object.keys(this.chat).length) {
        if (!this.chat.intentions.includes(this.$t("All"))) {
          this.chat.intentions.push(all);
        }
        if (!this.chat.entities.includes(this.$t("All"))) {
          this.chat.entities.push(all);
        }
        if (this.createAutomation.automationSetup.action === "sendTemplate") {
          this.getTemplates();
        }
      }
    },
    "createAutomation.automationSetup.intentions": {
      immediate: true,
      handler() {
        if (this.createAutomation.automationSetup.intentions.length > 1 && this.createAutomation.automationSetup.intentions.includes(this.$t("All"))) {
          this.createAutomation.automationSetup.intentions = [this.$t("All")];
        }
      }
    },
    "createAutomation.automationSetup.entities": {
      immediate: true,
      handler() {
        if (this.createAutomation.automationSetup.entities.length > 1 && this.createAutomation.automationSetup.entities.includes(this.$t("All"))) {
          this.createAutomation.automationSetup.entities = [this.$t("All")];
        }
      }
    },
    "createAutomation.automationSetup.action": {
      immediate: true,
      handler() {
        if (this.createAutomation.automationSetup.action !== null && this.createAutomation.automationSetup.action === "sendTemplate" && this.templateData.length === 0) {
          this.getTemplates();
        }
      }
    },
  },
  computed: {},
  beforeMount() {
    this.getChats();
  },
  methods: {
    getChats() {
      this.$http
        .get("/p/chat/list", {
          params: { acc: this.$store.state.acc.current_acc.id },
        })
        .then((response) => {
          this.chatbotData = response.data.data;
        })
        .catch((error) => {
          if (typeof error.request !== "undefined")
            if (typeof error.request.response !== "undefined")
              error.message = JSON.parse(error.request.response).message;
            else error.message = this.$t("UnexpectedErrorLoadX", ["chatbots"]);
          this.$vs.notify({
            title: this.$t("UnexpectedError"),
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "warning",
            position: "top-right",
            time: 4000,
          });
        });
    },
    async createNewAutomation() {
      try {
        this.validationErrors = {};

        const requiredFields = {
          title: this.createAutomation.title,
          chat: this.chat,
          intentions: this.createAutomation.automationSetup.intentions,
          stages: this.createAutomation.automationSetup.stages,
        };

        if (this.createAutomation.automationSetup.action === 'sendMessage') {
          requiredFields.message = this.createAutomation.automationSetup.message;
        }

        if (this.createAutomation.automationSetup.action === 'sendTemplate') {
          requiredFields.template = this.createAutomation.automationSetup.templateID;
        }

        Object.keys(requiredFields).forEach(key => {
          const value = requiredFields[key];
          if ((Array.isArray(value) && !value.length) || (!Array.isArray(value) && !value)) {
            this.validationErrors[key] = true;
          }
        });

        if (Object.keys(this.validationErrors).length) {
          this.$vs.notify({
            time: 2500,
            title: this.$t("Error"),
            text: this.$t("MissingFields"),
            iconPack: "feather",
            icon: "icon-check",
            color: "danger",
            position: "top-right",
          });
          return;
        }

        const body = this.createAutomation;
        body.chat = this.chat._id;

        if (this.createAutomation.automationSetup.action === "sendTemplate") {
          body.automationSetup.message = null;
        }

        if (this.createAutomation.automationSetup.action === "sendMessage") {
          body.automationSetup.templateID = null;
        }
        // const timeDay = parseInt(body.automationSetup.timeHour);
        const timeHour = parseInt(body.automationSetup.timeHour);
        const timeMinute = parseInt(body.automationSetup.timeMinute);
        body.automationSetup.timeCalculated = (/* (timeDay * 24 * 60) + */ (timeHour * 60) + timeMinute) * 1000;
        // body.automationSetup.timeDay = timeDay;
        body.automationSetup.timeHour = timeHour;
        body.automationSetup.timeMinute = timeMinute;

        await axios.post("/p/automation/create", body, {
          headers: {
            Authorization: "Bearer " + this.$store.state.auth.accessToken,
            "Content-Type": "Application/json",
          }
        });
        this.$vs.notify({
          time: 2500,
          title: this.$t("Confirmation"),
          text: this.$t("AutomationCreated"),
          iconPack: "feather",
          icon: "icon-check",
          color: "success",
          position: "top-right",
        });

        this.$emit("closeCreatePopup");
        this.$emit("refreshTable");
      } catch (e) {
        let title = this.$t("Error")
        if (e.response && e.response.status === 400) title = this.$t("AutomationLimitReached");
        this.$vs.notify({
          time: 2500,
          title: title,
          text: e,
          iconPack: "feather",
          icon: "icon-check",
          color: "danger",
          position: "top-right",
        });
      }
    },
    async getTemplates() {
      const accId = this.$store.state.acc.current_acc.id;
      const chatId = this.chat._id;

      this.$vs.notify({
        time: 4000,
        title: this.$t("Warning"),
        text: this.$t("TemplatesLoading"),
        iconPack: "feather",
        icon: "icon-x",
        color: "warning",
        position: "top-right",
      });

      this.$vs.loading();

      try {
        const response = await this.$http.post(`/p/chat/inbox/load360DialogTemplates/${accId}`, {
          chat: chatId,
        });
        response.data.data.map((template) => {
          this.templateData.push({ id: template._id, title: template.title });
          return;
        });
      } catch (error) {
        this.$vs.notify({
          time: 4000,
          title: this.$t("Error"),
          text: this.$t("NoChatbotTemplate"),
          iconPack: "feather",
          icon: "icon-x",
          color: "danger",
          position: "top-right",
        });
      } finally {
        this.$vs.loading.close();
      }
    }
  }
};
</script>
<style>
.vs-input-number span {
  width: 42.88px;
}

.background-color {
  background-color: #e5e1fe;
}

.danger {
  color: rgba(255, 35, 35, 0.884);
  font-size: 0.65rem;
}
</style>
