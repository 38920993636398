<template>
  <div
    v-if="cardData !== undefined"
    class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base"
  >
    <vx-card id="card" :title="cardTitle" :cardHeader="cardHeader">
      <p v-if="cardData.length === 0">Não há dados para exibir</p>
      <ul id="List" class="mt-5 overflow-y-auto pr-5 flex flex-col space-y-4">
        <li
          v-for="(item, index) in sortedData"
          :key="index"
          class="flex justify-between"
        >
          <p id="liText" class="text-lg">{{ item.entity }}</p>
          <div class="flex space-x-4 items-center justify-center">
            <p class="text-lg">{{ item.conversations }}</p>
          </div>
        </li>
      </ul>
    </vx-card>
  </div>
</template>

<script>
export default {
  props: {
    cardTitle: String,
    cardData: Array,
    cardHeader: String,
  },
  computed: {
    sortedData: function () {
      const dataArr = this.cardData || [];
      return dataArr.sort((a, b) => {
        const entityA = a.entity || "";
        const entityB = b.entity || "";
        return entityA.localeCompare(entityB);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#card {
  min-height: 100px;
  max-height: 400px;
}
#List {
  max-height: 250px;
}
#liText {
  white-space: nowrap;
  text-overflow: ellipsis;
}
*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 20px;
}
</style>
