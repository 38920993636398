<template>
  <div class="">
    <div class="flex justify-center w-full mb-5">
      <vs-button
        type="border"
        icon-pack="feather"
        icon="icon-refresh-cw"
        icon-after
        @click="clear()"
      >
        {{ $t("TestChatbot") }}
      </vs-button>
    </div>
    <div class="overflow-hidden iframe-container" :class="{ hidden: !ready }">
      <iframe
        id="chatbot-ai"
        :key="iframeKey"
        :src="widgetURL"
        width="100%"
        height="100%"
        class="border-none"
      />
    </div>
  </div>
</template>
<script>
import socket from "@/views/apps/chatbots/SocketAI";
export default {
  name: "ChatAIPreview",
  props: {
    chatbot: {
      type: Object,
    },
  },
  data() {
    return {
      iframeKey: 0,
      message: "",
      typing: false,
      ready: false,
      messageEventListener: null,
    };
  },
  computed: {
    widgetURL() {
      return `${process.env.VUE_APP_WIDGET_AI_PLUGIN_URL}/index.html?acc=preview&chat=preview&resetChat=true&isChatOpen=true`;
    },
  },
  beforeDestroy() {
    window.removeEventListener("message", this.messageEventListener);
    socket.disconnect();
  },
  mounted() {
    const thisIns = this;
    socket.auth = {
      chatID: this.chatbot._id,
      token: this.$store.state.auth.accessToken,
    };
    socket.connect();

    this.messageEventListener = (event) => {
      if (event.data === "ready") thisIns.serveWidget();
      if (event.data && event.data.new_user_message)
        thisIns.sendMessage(event.data.new_user_message);
    };

    //posted message from iframe
    window.addEventListener("message", this.messageEventListener);

    this.serveWidget();
  },
  methods: {
    serveWidget() {
      //serve widget
      const serveInterval = setInterval(() => {
        serveWidget();
      }, 1000);
      const serveWidget = () => {
        const iframe = document.getElementById("chatbot-ai");
        if (iframe != null) {
          this.ready = true;
          let message = {};
          if (this.chatbot.name) message.title = this.chatbot.name;
          if (this.chatbot.chatAvatar != null)
            message.img = this.chatbot.chatAvatar;
          if (this.chatbot.color != null) message.color = this.chatbot.color;
          if (iframe.contentWindow)
            iframe.contentWindow.postMessage(message, "*");
          clearInterval(serveInterval);
        }
      };
    },
    sendMessage(msg) {
      socket.emit("private message", msg, (message) => {
        const iframe = document.querySelector("#chatbot-ai");
        if (iframe && iframe.contentWindow)
          iframe.contentWindow.postMessage(
            { new_message: { author: "assistant", text: message } },
            "*"
          );
      });
    },
    clear() {
      socket.emit("clear");
      this.iframeKey++;
      this.serveWidget();
    },
  },
};
</script>
<style scoped lang="scss">
.iframe-container {
  width: 450px;
  height: 606px;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 0 10px;
}
</style>
